import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiEmploymentTypeSlice = createApi({
  reducerPath: "EmploymentTypeApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["EmploymentType"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListEmploymentType: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/employment_type`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: "EmploymentType",
                id,
              })),
              { type: "EmploymentType", id: "LIST" },
            ]
          : [{ type: "EmploymentType", id: "LIST" }],
    }),

    createEmploymentType: builder.mutation({
      query: (newEmploymentType) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/employment_type`,
        method: "POST",
        body: newEmploymentType,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "EmploymentType", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateEmploymentType: builder.mutation({
      query: ({ id, ...updatedEmploymentType }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/employment_type/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedEmploymentType.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "EmploymentType", id }] : [],
    }),

    deleteEmploymentType: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/employment_type/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "EmploymentType", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/employment_type/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "EmploymentType", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListEmploymentTypeQuery,
  useCreateEmploymentTypeMutation,
  useUpdateEmploymentTypeMutation,
  useDeleteEmploymentTypeMutation,
  useSwapMutation,
} = apiEmploymentTypeSlice;
