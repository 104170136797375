import React, { useState } from "react";
import TableBaseComponent from "../../../components/FormComponent/Table";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";
import {
  useDeleteEmployeeMutation,
  useGetListEmployeesQuery,
} from "../../../store/Employee";
import {
  countAgeToNow,
  handlePageChange,
  handlePageSizeChange,
} from "../../../constants/ultil";
import CreateEditEmployee from "./CreateEditEmployee";
import PopConfirmBase from "../../../components/PopupConfirm";
import { message } from "antd";
import withPermissionCheck from "../../../constants/PermissionHook";

const ListEmployeesContainer = (props) => {
  const { t } = useTranslation();
  const {
    idCompany,
    hasCreatePermission,
    hasDetailPermission,
    hasDeletePermission,
  } = props;
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [modalCreate, setModalCreate] = useState({
    data: {},
    isOpen: false,
  });

  const {
    data: listEmployees,
    refetch,
    isFetching,
  } = useGetListEmployeesQuery(
    {
      id: idCompany,
      params: { ...params },
    },
    {
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );
  const [deleteEmployee] = useDeleteEmployeeMutation();
  const onDeleteEmployee = async (record) => {
    try {
      await deleteEmployee(record.id).unwrap();
      refetch();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const columns = [
    {
      title: t("employee.employeeID"),
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("account.fullName"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("employee.age"),
      key: "age",
      dataIndex: "age",
      render: (_, record) => {
        return countAgeToNow(record?.date_of_birth);
      },
    },
    {
      title: t("common.hierarchy"),
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return record?.hierarchy?.name;
      },
    },
    {
      title: t("employee.position"),
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return record?.position?.name;
      },
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[var(--primary-color)] cursor-pointer font-medium">
          <div
            className={`mr-5 text-[var(--primary-color)] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                setModalCreate({ data: record, isOpen: true });
              }
            }}
          >
            {t("setting.edit")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteEmployee(record)}
            >
              <div className="font-medium text-[var(--primary-color)] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];
  return (
    <div className="p-4">
      <div className="flex justify-end mb-4">
        <ButtonBase
          title={
            <div className="flex text-[11px] justify-center">
              <div className="flex items-center">{t("employee.addStaff")}</div>
              <div className="text-xl ml-2">+</div>
            </div>
          }
          className="!bg-white !text-[#0B0A0A] w-[127px]"
          onClick={() => setModalCreate({ isOpen: true })}
          disabled={!hasCreatePermission}
        />
      </div>
      <TableBaseComponent
        columns={columns}
        dataSource={listEmployees?.data?.data}
        paginationOptions={{
          total: listEmployees?.data?.total,
          defaultPageSize: params.limit,
          current: params?.page,
        }}
        handlePageChange={(pageChange) =>
          handlePageChange(pageChange, setParams)
        }
        handlePageSizeChange={(current, size) =>
          handlePageSizeChange(size, setParams)
        }
        loading={isFetching}
      />
      {modalCreate.isOpen && (
        <CreateEditEmployee
          modalCreate={modalCreate}
          setModalCreate={setModalCreate}
          idCompany={idCompany}
          refetchList={refetch}
        />
      )}
    </div>
  );
};
export default withPermissionCheck(ListEmployeesContainer, {
  create: "create-person",
  detail: "detail-person",
  delete: "delete-person",
});
