import React, { useEffect, useState } from "react";
import HeaderContent from "../../../components/HeaderContent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InputComponent from "../../../components/FormComponent/Input";
import { Col, Form, Row, Spin, message, Input, Radio, Button } from "antd";
import { MinusCircleOutlined } from "@ant-design/icons";
import ButtonBase from "../../../components/Button";
import dayjs from "dayjs";
import {
  useCreateConceptMutation,
  useGetConceptByIdQuery,
  useUpdateConceptMutation,
} from "../../../store/Concepts";
import { LIST_ANALYSIS_TARGET, NO_CHOOSE_SCORE } from "../../../constants";
import { useValidationRules } from "../../../constants/rules";
import withPermissionCheck from "../../../constants/PermissionHook";
import { useGetListScoreScaleQuery } from "../../../store/Settings/ScoreScale";
import SelectComponent from "../../../components/FormComponent/Select";

const { TextArea } = Input;

const CreatEditConceptContainer = ({
  hasCreatePermission,
  hasEditPermission,
  hasDetailPermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get id from URL
  const [form] = Form.useForm();
  const { InputTextRule } = useValidationRules();
  const [params] = useState({
    limit: 1000,
    page: 1,
  });
  const [listScaleScore, setListScaleScore] = useState([]);

  useEffect(() => {
    if (!hasCreatePermission || !hasDetailPermission) {
      navigate("/403");
    }
  }, [hasCreatePermission, navigate, hasDetailPermission]);

  const { data: detailConcept, isLoading: isLoadingDetail } =
    useGetConceptByIdQuery(id || "", {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });
  const { data: listScoreScale } = useGetListScoreScaleQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [textAreas, setTextAreas] = useState([{ key: dayjs().valueOf() }]);

  const handleAddTextArea = () => {
    setTextAreas([...textAreas, { key: dayjs().valueOf() }]);
  };
  const handleRemoveTextArea = (indexToRemove) => {
    let array = [...textAreas];
    const updatedTextAreas = array.filter(
      (_, index) => index !== indexToRemove
    );

    setTextAreas(updatedTextAreas);
  };
  const [createConcept, { isLoading: isLoadingCreate }] =
    useCreateConceptMutation();
  const [updateConcept, { isLoading: isLoadingUpdate }] =
    useUpdateConceptMutation();

  useEffect(() => {
    const listScore = [
      ...(listScoreScale?.data?.data?.map((item) => ({
        value: item.id,
        label: `${item.name}${t("history.pointFormat")}`,
      })) ?? []),
    ];
    listScore.unshift({ label: t("history.none"), value: NO_CHOOSE_SCORE });
    setListScaleScore(listScore);
  }, [listScoreScale, t]);

  useEffect(() => {
    if (detailConcept) {
      const listPrompts = detailConcept?.data?.prompts.map((prompt) => ({
        ...prompt,
        key: prompt.id,
      }));
      if (listPrompts.length > 0) {
        setTextAreas(listPrompts);
      }
      form.setFieldsValue({
        name: detailConcept.data?.name,
        criteria: detailConcept.data?.criteria,
        score_scale_id: detailConcept.data?.score_scale_id
          ? detailConcept.data?.score_scale_id
          : NO_CHOOSE_SCORE,
        format_output: detailConcept.data?.format_output,
        type: detailConcept.data?.type,
        ...listPrompts.reduce(
          (acc, record, _) => ({
            ...acc,
            [`prompt-${record.id}`]: record.prompt,
            [`title-${record.id}`]: record.title,
            // [`criteria-${record.id}`]: record.criteria,
          }),
          {}
        ),
      });
    } else {
      form.setFieldsValue({
        type: "organization",
      });
    }
  }, [detailConcept, form]);

  const onSubmitData = async (values) => {
    const output = [];

    Object.keys(values).forEach((key) => {
      const [type, id] = key.split("-"); // Tách key thành 2 phần: type (title hoặc prompt) và id
      // Nếu key là 'title', ta tìm 'prompt' có cùng id
      if (type === "title") {
        const title = values[key];
        const promptKey = `prompt-${id}`;
        const prompt = values[promptKey];
        // const criteriaKey = `criteria-${id}`;
        // const criteria = values[criteriaKey];
        if (prompt !== undefined) {
          // Gom cặp { title, prompt } vào một object và đẩy vào array output
          output.push({
            title,
            prompt: prompt,
            // criteria: criteria,
          });
        }
      }
    });
    const payload = {
      name: values.name,
      criteria: values.criteria,
      type: values.type,
      format_output: values?.format_output,
      score_scale_id:
        values.score_scale_id === NO_CHOOSE_SCORE
          ? null
          : values.score_scale_id,
      prompts: output,
    };
    try {
      if (id) {
        await updateConcept({
          id: id,
          data: payload,
        }).unwrap();
        message.success(t("setting.createSuccess"));
      } else {
        await createConcept(payload).unwrap();
        message.success(t("setting.createSuccess"));
        form.resetFields();
      }
      navigate("/list-concepts");
    } catch (error) {
      if (error?.data?.name) {
        message.error(error?.data?.name?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={
            id ? t("concept.editConcept") : t("concept.createNewConcept")
          }
          textPreviousPage={t("account.backToList")}
          className="ml-3"
          actionBack={() => {
            navigate("/list-concepts");
          }}
        />
      </div>

      <div className="pt-12 flex justify-center">
        {isLoadingDetail ? (
          <Spin></Spin>
        ) : (
          <Form form={form} onFinish={onSubmitData}>
            <div className="bg-white w-[760px] h-full px-12 pt-12">
              <Row>
                <Col span={8}>
                  {t("setting.title")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                      InputTextRule,
                    ]}
                  >
                    <InputComponent />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("concept.analysisTarget")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item name="type">
                    <Radio.Group
                      options={LIST_ANALYSIS_TARGET}
                      className="hover:!border-[var(--primary-color)]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("concept.scoringCriteria")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="criteria"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                    ]}
                  >
                    <TextArea
                      rows={4}
                      className="hover:!border-[var(--primary-color)]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>{t("concept.formOutput")}</Col>
                <Col span={16}>
                  <Form.Item name="format_output">
                    <TextArea
                      rows={4}
                      className="hover:!border-[var(--primary-color)]"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("history.graphFormat")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="score_scale_id"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                    ]}
                  >
                    <SelectComponent
                      allowClear
                      showSearch
                      optionFilterProp="label"
                      options={listScaleScore}
                    />
                  </Form.Item>
                </Col>
              </Row>
              {textAreas.map((textArea, index) => {
                return (
                  <div key={textArea.key}>
                    <Row>
                      {index === 0 ? (
                        <Col span={8}>
                          {t("concept.prompt")}
                          <span className="text-[#ff0000]">*</span>
                        </Col>
                      ) : (
                        <Col span={8}></Col>
                      )}
                      <Col span={16}>
                        {/* <div className="mb-1">タイトル</div> */}
                        <Form.Item
                          name={`title-${textArea.key}`}
                          rules={[
                            {
                              required: true,
                              message: t("setting.pleaseEnterField"),
                            },
                            InputTextRule,
                          ]}
                        >
                          <InputComponent
                            value={textArea.prompt}
                            className="hover:!border-[var(--primary-color)]"
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}></Col>
                      <Col span={16} className="relative">
                        {/* <div className="mb-1">スコアリング基準</div> */}

                        <Form.Item
                          name={`prompt-${textArea.key}`}
                          rules={[
                            {
                              required: true,
                              message: t("setting.pleaseEnterField"),
                            },
                          ]}
                        >
                          <TextArea
                            value={textArea.prompt}
                            rows={4}
                            className="hover:!border-[var(--primary-color)]"
                          />
                        </Form.Item>
                        <div className="absolute top-6 right-[-30px]">
                          <Button
                            icon={<MinusCircleOutlined />}
                            type="link"
                            danger
                            onClick={() => handleRemoveTextArea(index)}
                            disabled={textAreas.length === 1}
                          />
                        </div>
                      </Col>
                      {/* <Col span={8}></Col>
                      <Col span={16}>
                        <div className="mb-1">プロンプト内容</div>
                        <Form.Item
                          name={`prompt-${textArea.key}`}
                          rules={[
                            {
                              required: true,
                              message: t("setting.pleaseEnterField"),
                            },
                          ]}
                        >
                          <TextArea
                            value={textArea.prompt}
                            rows={4}
                            className="hover:!border-[var(--primary-color)]"
                          />
                        </Form.Item>
                      </Col> */}
                    </Row>
                  </div>
                );
              })}
              <Row>
                <Col span={8}></Col>
                <Col span={16}>
                  <Button type="text" onClick={handleAddTextArea}>
                    + <div className="ml-2">{t("setting.create")}</div>
                  </Button>
                </Col>
              </Row>
            </div>
            <div className="flex justify-end mt-6">
              <ButtonBase
                className="w-[108px] h-[36px]"
                title={t("common.submit")}
                htmlType="submit"
                disabled={!hasEditPermission && id}
                loading={isLoadingUpdate || isLoadingCreate}
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
export default withPermissionCheck(CreatEditConceptContainer, {
  create: "create-concept",
  edit: "update-concept",
  detail: "detail-concept",
});
