import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../api/apiConfig';

export const apiCompanySlice = createApi({
  reducerPath: 'companyApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Company'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListCompany: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Company', id })), { type: 'Company', id: 'LIST' }]
          : [{ type: 'Company', id: 'LIST' }],
    }),

    getCompanyById: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/company/${id}`,
      providesTags: (result, error, id) => [{ type: 'Company', id }],
    }),

    createCompany: builder.mutation({
      query: (newCompany) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company`,
        method: 'POST',
        body: newCompany,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'Company', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    updateCompany: builder.mutation({
      query: ({ id, ...updatedCompany }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company/${id}`,
        method: 'PUT', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedCompany.data,
      }),
      invalidatesTags: (result, error, { id }) => result?  [{ type: 'Company', id }] : [],
    }),

    deleteCompany: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Company', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetListCompanyQuery,
  useGetCompanyByIdQuery,
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
  useDeleteCompanyMutation,
} = apiCompanySlice;