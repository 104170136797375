import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TableBaseComponent from "../../components/FormComponent/Table";
import HeaderContent from "../../components/HeaderContent";
import ModalDetailReport from "./ModalDetailReport";
import FilterHistoryContainer from "./Filter";
import {
  useDeleteHistoryReportMutation,
  useGetListReportsQuery,
} from "../../store/Implement";
import { handlePageChange, handlePageSizeChange } from "../../constants/ultil";
import PopConfirmBase from "../../components/PopupConfirm";
import dayjs from "dayjs";
import { useGetListJobTitleQuery } from "../../store/Settings/JobTitle";
import { useGetListPositionsQuery } from "../../store/Settings/Positions";
import { message } from "antd";
import withPermissionCheck from "../../constants/PermissionHook";
import { LIST_ANALYSIS_TARGET } from "../../constants";
import ModalDetailConcept from "./ModalDetailConcept";

const ListHistoryContainer = ({ hasDetailPermission, hasDeletePermission }) => {
  const { t } = useTranslation();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const [paramsQuery] = useState({
    limit: 1000,
    page: 1,
  });
  const [modalDetail, setModalDetail] = useState({
    data: [],
    isOpen: false,
  });
  const [detailConcept, setDetailConcept] = useState({
    data: [],
    isOpen: false,
  });
  const { data: listReportHistory, isFetching } = useGetListReportsQuery(
    params,
    {
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );
  const { data: listJobTitle } = useGetListJobTitleQuery(paramsQuery, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  const { data: listPositions } = useGetListPositionsQuery(paramsQuery, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteHistoryReport] = useDeleteHistoryReportMutation();

  const onDeleteHistoryReport = async (record) => {
    try {
      await deleteHistoryReport(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(error?.data?.message);
    }
  };

  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("concept.analysisTarget"),
      key: "target",
      dataIndex: "target",
      render: (_, record) => {
        return LIST_ANALYSIS_TARGET.find(
          (item) => item.value === record?.target
        ).label;
      },
    },
    {
      title: t("history.staffName"),
      key: "name",
      dataIndex: "name",
      render: (_, record) => {
        return record?.target === "individual" && record?.name;
      },
    },
    {
      title: t("history.concept"),
      key: "concept",
      dataIndex: "concept",
      render: (_, record) => {
        return (
          <div
            className="underline text-[var(--primary-color)] cursor-pointer underline-offset-2"
            onClick={() => {
              if (hasDetailPermission) {
                setDetailConcept({ data: record, isOpen: true });
              }
            }}
          >
            {record?.concept?.name}
          </div>
        );
      },
    },
    {
      title: t("company.companyName"),
      key: "companyName",
      dataIndex: "companyName",
      render: (_, record) => {
        return record?.company?.name;
      },
    },
    {
      title: t("common.jobTitle"),
      key: "jobTitle",
      dataIndex: "jobTitle",
      render: (_, record) => {
        return listJobTitle?.data?.data?.find(
          (item) => item.id === record?.job_title_id
        )?.name;
      },
    },
    {
      title: t("history.implementDate"),
      key: "implementDate",
      dataIndex: "implementDate",
      render: (_, record) => {
        return dayjs(record?.created_at).format("DD/MM/YYYY");
      },
    },
    {
      title: t("employee.position"),
      key: "position",
      dataIndex: "position",
      render: (_, record) => {
        return (
          record?.target === "individual" &&
          listPositions?.data?.data?.find(
            (item) => item.id === record?.position_id
          )?.name
        );
      },
    },

    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[var(--primary-color)] cursor-pointer font-medium">
          <div
            className={`mr-5 text-[var(--primary-color)] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                setModalDetail({ data: record, isOpen: true });
              }
            }}
          >
            {t("history.resultDetail")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteHistoryReport(record)}
              okButtonProps={{ loading: false }}
            >
              <div className="font-medium text-[var(--primary-color)] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("history.searchHistory")}
          className="ml-3"
        />
      </div>
      <FilterHistoryContainer
        listJobTitle={listJobTitle?.data?.data}
        listPositions={listPositions?.data?.data}
        paramsSearch={params}
        setParamsSearch={setParams}
      />
      <div className="p-4">
        <TableBaseComponent
          loading={isFetching}
          columns={columns}
          dataSource={listReportHistory?.data?.data}
          paginationOptions={{
            total: listReportHistory?.data?.total,
            defaultPageSize: params.limit,
            current: params.page || 1,
          }}
          handlePageChange={(pageChange) => {
            handlePageChange(pageChange, setParams);
          }}
          handlePageSizeChange={(current, size) => {
            handlePageSizeChange(size, setParams);
          }}
        />
      </div>
      {modalDetail.isOpen && (
        <ModalDetailReport
          modalDetail={modalDetail}
          setModalDetail={setModalDetail}
        />
      )}
      {detailConcept.isOpen && (
        <ModalDetailConcept
          modalDetail={detailConcept}
          setModalDetail={setDetailConcept}
        />
      )}
    </div>
  );
};

export default withPermissionCheck(ListHistoryContainer, {
  view: "list-reports",
  detail: "detail-report",
  delete: "detail-report",
});
