import React from "react";
import "./index.scss";
import images from "../../assets/image-base";
const HeaderContent = (props) => {
  const { textPreviousPage, textCurrentPage, actionBack, className } = props;
  return (
    <div className={`${className} bg-white header-content`}>
      {textPreviousPage && (
        <div className="flex mb-[15px] cursor-pointer" onClick={actionBack}>
          <img src={images.common.arrowBack} alt="arrow-back" />
          <div className="ml-2 text-lg">{textPreviousPage}</div>
        </div>
      )}
      <div className="text-xl font-bold">{textCurrentPage}</div>
    </div>
  );
};
export default HeaderContent;
