/* eslint-disable import/first */
//** */
import { init as initApm } from '@elastic/apm-rum';

const serviceVersion = require('../package.json').version;
initApm({
  serviceName: process.env.REACT_APP_SERVICE_NAME ?? 'example-next',
  serverUrl: process.env.REACT_APP_APM_SERVER_URL ?? 'http://localhost:8200',
  serviceVersion,
  environment: process.env.REACT_APP_ENVIRONMENT ?? 'local',
  apiVersion: 3,
  distributedTracingOrigins: [process.env.REACT_APP_APM_API_BASE_URL ?? 'http://localhost:8080'],
  active: ['production'].includes(process.env.REACT_APP_ENVIRONMENT)
});
//** */

import { ConfigProvider } from 'antd';
import 'antd/dist/reset.css';
import { createBrowserHistory } from 'history';
import React, { Suspense, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from '../src/store/index'; // Import store
import App from './App';
import './index.scss';
import './locales/i18n';

export const history = createBrowserHistory();
const root = ReactDOM.createRoot(document.getElementById('root'));
const CustomRouter = ({ history, ...props }) => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};

// document.title = process.env.REACT_APP_TITLE || "GPT-BrandBook";
const primaryColor = process.env.REACT_APP_PRIMARY_COLOR;

document.documentElement.style.setProperty('--primary-color', primaryColor);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <CustomRouter history={history}>
          <Suspense fallback={null}>
            <ConfigProvider
              theme={{
                token: {
                  fontFamily: 'Noto Sans JP , sans-serif'
                }
              }}
            >
              <App />
            </ConfigProvider>
          </Suspense>
        </CustomRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);
