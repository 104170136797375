import React, { useEffect, useState, useRef } from "react";
import { message, Modal, Spin } from "antd";

import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  useGetReportInfoQuery,
  useUpdateHtmlReportMutation,
  useLazyExportReportQuery,
} from "../../store/Implement";
import ChartsComponent from "../../components/Chart";
import "./index.scss";
import HeaderEditor from "./HeaderEditor";
import ContentEditor from "./ContentEditor";

const CustomReportContainer = () => {
  const { id } = useParams(); // Get id from URL
  const { t } = useTranslation();
  const [dataChart, setDataChart] = useState({});
  const [lineData, setLineData] = useState({});
  const [isOpenLoading, setIsLoading] = useState(false);
  const [isLoadingPreview, setIsLoadingPreview] = useState(false);
  const { data: infoReport } = useGetReportInfoQuery(id || "", {
    skip: !id,
    refetchOnMountOrArgChange: true,
  });
  const [forceUpdate, setForceUpdate] = useState(0);
  const [jsonData, setJsonData] = useState([]);
  const [jsonHtml, setJsonHtml] = useState("");
  const [jsonCSS, setJsonCSS] = useState("");
  const [dataTable, setDataTable] = useState([]);

  const [dataUrl, setDataUrl] = useState("");
  const canvasRef = useRef(null);
  const [typeChart, setTypeChart] = useState(() => {
    if (infoReport?.data?.settings) {
      return JSON.parse(infoReport.data.settings).chart_type;
    }
    return "bar";
  });
  const [scoreScale, setScoreScale] = useState("");

  const [isPreview, setOpenPreview] = useState(false);
  const [filePreview, setFilePreview] = useState(false);

  const [updateHtmlReport] = useUpdateHtmlReportMutation();

  const [triggerExportPdf] = useLazyExportReportQuery();
  const downloadContent = async (typeExport) => {
    try {
      const { blob, fileName } = await triggerExportPdf({
        idReport: id,
        key: typeExport,
      }).unwrap();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
      message.success(t("setting.downloadSuccess"));
    } catch (error) {
      if (error.status === 404) {
        message.error(t("report.pleaseDownloadAfterSave"));
      } else {
        message.error(error?.data?.message);
      }
    }
    setIsLoading(false);
  };
  const openPreview = async () => {
    try {
      const { blob } = await triggerExportPdf({
        idReport: id,
        key: "export-html-to-pdf",
      }).unwrap();
      const fileUrl = URL.createObjectURL(blob);
      setFilePreview(fileUrl);
      setOpenPreview(true);
    } catch (error) {
      if (error.status === 404) {
        message.error(t("report.pleaseDownloadAfterSave"));
      } else {
        message.error(error?.data?.message);
      }
    }
    setIsLoadingPreview(false);
    setIsLoading(false);
  };

  useEffect(() => {
    localStorage.removeItem("gjsProject");
  }, []);

  useEffect(() => {
    if (infoReport?.data) {
      const parsedJson = infoReport.data.json
        ? JSON.parse(infoReport.data.json)
        : [];
      if (infoReport.data?.html) {
        setJsonHtml(infoReport.data?.html);
      }
      if (infoReport.data?.css) {
        setJsonCSS(infoReport.data?.css);
      }
      setJsonData(parsedJson);
      setDataTable(infoReport.data?.charts);
      setScoreScale(infoReport?.data?.score_scale);
    }
    setForceUpdate((prev) => prev + 1);
  }, [infoReport]);

  useEffect(() => {
    const handleTabClose = (event) => {
      localStorage.removeItem("gjsProject"); // Xóa gjsProject
    };

    window.addEventListener("unload", handleTabClose);

    return () => {
      window.removeEventListener("unload", handleTabClose);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);

    if (canvasRef.current) {
      const imgBase64 = canvasRef.current.toBase64Image();
      const iframe = document.querySelector("#grapesjs-react iframe");
      if (window.editor) {
        const currentHtml = window.editor.getHtml();
        const updatedHtml = currentHtml.replace(
          /<img[^>]+src="([^">]+)"[^>]*>/,
          `<img src="${dataUrl}" style="width: 100%; height: 50%; margin: 20px 0px" />`
        );
        window.editor.setComponents(updatedHtml);
      }
      if (iframe) {
        const iframeDoc =
          iframe.contentDocument || iframe.contentWindow.document;
        const imgChart = iframeDoc.getElementById("chart-image");
        if (imgChart) {
          imgChart.src = imgBase64;
        }
      }
      setDataUrl(imgBase64);
    }
    setIsLoading(false);
  }, [dataChart, typeChart, dataUrl, lineData]);

  const handleSaveContent = async (key, typeExport) => {
    setIsLoading(true);
    const html = window.editor?.getHtml();
    const css = window.editor?.getCss();
    const dataToSend = {
      html,
      css,
      settings: {
        chart_type: canvasRef?.current?.config?._config.type,
      },
    };

    try {
      const res = await updateHtmlReport({ id, data: dataToSend });
      if (res.data?.success) {
        if (key === "preview") {
          openPreview();
        } else if (key === "download") {
          downloadContent(typeExport);
        } else {
          localStorage.removeItem("gjsProject");
          message.success(t("setting.updateSuccess"));
          setIsLoading(false);
        }
      }
    } catch (error) {
      if (error?.data?.html) {
        message.error(error?.data?.html?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };
  console.log(dataUrl);

  return (
    <>
      <HeaderEditor
        handleSaveContent={handleSaveContent}
        typeChart={typeChart}
        setTypeChart={setTypeChart}
        isOpenLoading={isOpenLoading}
        isLoadingPreview={isLoadingPreview}
        setIsLoadingPreview={setIsLoadingPreview}
      />
      {dataUrl && (
        <ContentEditor
          forceUpdate={forceUpdate}
          jsonHtml={jsonHtml}
          jsonCSS={jsonCSS}
          jsonData={jsonData}
          dataTable={dataTable}
          dataUrl={dataUrl}
          scoreScale={scoreScale}
        />
      )}
      <div className="bg-white mt-[-900px]">
        <ChartsComponent
          setDataChart={setDataChart}
          dataReport={dataChart}
          infoReport={infoReport?.data}
          typeChart={typeChart}
          setTypeChart={setTypeChart}
          canvasRef={canvasRef}
          hiddenChart={true}
          lineData={lineData}
          setLineData={setLineData}
          scoreScale={scoreScale}
        />
      </div>
      <Modal
        open={isPreview}
        width={1400}
        footer={false}
        onCancel={() => setOpenPreview(false)}
      >
        <div className="mt-6">
          <iframe
            src={filePreview}
            title="preview"
            className="w-full h-[700px]"
          />
        </div>
      </Modal>
      <Modal
        open={isOpenLoading}
        footer={false}
        closeIcon={false}
        width={500}
        className="custom-modal"
      >
        <div className="flex justify-center mt-4">
          <Spin></Spin>
        </div>
      </Modal>
    </>
  );
};

export default CustomReportContainer;
