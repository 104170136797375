import { Input } from "antd";
import React from "react";

const InputComponent = (props) => {
  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div>
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <Input
        className={`${className} h-10 hover:!border-[var(--primary-color)] focus:!border-[var(--primary-color)] disabled:!border-[#dedede]`}
        {...otherProps}
      />
    </div>
  );
};
export default InputComponent;
