import React, { useState } from "react";
import ButtonBase from "../../components/Button/index";
import TableBaseComponent from "../../components/FormComponent/Table";
import { useTranslation } from "react-i18next";
import { Table, message } from "antd";
import HeaderContent from "../../components/HeaderContent";
import { useNavigate } from "react-router-dom";
import {
  useDeleteConceptMutation,
  useDeletePromptMutation,
  useGetListConceptsQuery,
  useCopyConceptMutation,
} from "../../store/Concepts";
import "./index.scss";
import PopConfirmBase from "../../components/PopupConfirm";
import { LIST_ANALYSIS_TARGET } from "../../constants";
import withPermissionCheck from "../../constants/PermissionHook";
import { handlePageChange, handlePageSizeChange } from "../../constants/ultil";

const ListConceptsContainer = ({
  hasCreatePermission,
  hasDetailPermission,
  hasDeletePermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const { data: listConcepts, isFetching } = useGetListConceptsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteConcept] = useDeleteConceptMutation();
  const [copyConcept] = useCopyConceptMutation();

  const [deletePrompt] = useDeletePromptMutation();
  const [confirmLoading, setConfirmLoading] = useState(false);
  const onDeleteConcept = async (record) => {
    // setConfirmLoading(true);
    try {
      await deleteConcept(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
      // setConfirmLoading(false);
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const onCopyConcept = async (record) => {
    try {
      const res = await copyConcept(record.id).unwrap();
      message.success(res?.message);
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const onDeletePrompt = async (record) => {
    setConfirmLoading(true);
    try {
      await deletePrompt(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
      setConfirmLoading(false);
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("concept.conceptName"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("concept.scoringCriteria"),
      key: "criteria",
      dataIndex: "criteria",
      render: (_, record) => {
        return (
          <div className="max-w-[700px] text-ellipsis">{record.criteria}</div>
        );
      },
    },
    {
      title: t("concept.numberOfPrompts"),
      key: "number_of_prompts",
      dataIndex: "number_of_prompts",
      width: 120,
      render: (_, record) => {
        return record.prompts.length;
      },
    },
    {
      title: t("concept.analysisTarget"),
      key: "type",
      dataIndex: "type",
      width: 100,

      render: (_, record) => {
        return LIST_ANALYSIS_TARGET.find((item) => item.value === record?.type)
          ?.label;
      },
    },
    {
      title: t("setting.operation"),
      key: "action",
      width: 180,

      render: (_, record) => (
        <div className="flex text-[var(--primary-color)] cursor-pointer font-medium">
          <div
            className={`mr-5 ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                navigate(`/list-concepts/edit/${record.id}`);
              }
            }}
          >
            {t("setting.edit")}
          </div>
          <div
            className={`mr-5 ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasCreatePermission) {
                onCopyConcept(record);
              }
            }}
          >
            {t("concept.copy")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteConcept(record)}
              okButtonProps={{ loading: confirmLoading }}
            >
              <div className="font-medium text-[var(--primary-color)] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];
  const expanderColumns = [
    {
      title: t("concept.promptID"),
      key: "id",
      dataIndex: "id",
      width: 150,
    },
    {
      title: t("concept.promptTilte"),
      key: "title",
      dataIndex: "title",
      width: 180,
    },
    {
      title: t("concept.promptTemplate"),
      key: "prompt",
      dataIndex: "prompt",
      render: (_, record) => {
        return (
          <div className="text-ellipsis max-w-[700px]">{record.prompt}</div>
        );
      },
    },
    {
      title: t("setting.operation"),
      key: "action",
      width: 180,
      render: (_, record) => (
        <div className="flex text-[var(--primary-color)] cursor-pointer font-medium">
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeletePrompt(record)}
              okButtonProps={{ loading: confirmLoading }}
            >
              <div className="font-medium">{t("setting.delete")}</div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];
  const expandedRowRender = (record) => {
    return (
      <Table
        className="table-container"
        columns={expanderColumns}
        dataSource={record.prompts.map((item) => ({
          ...item,
          key: item.id,
        }))}
        pagination={false}
      />
    );
  };

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("common.listOfConcept")}
          className="ml-3"
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px] ml-[10px]"
            title={t("account.newRegistration")}
            onClick={() => navigate("/list-concepts/create")}
            disabled={!hasCreatePermission}
          />
        </div>
      </div>
      <div className="p-4">
        <TableBaseComponent
          expandable={{
            expandedRowRender,
            // Disable expand icon when prompts length is 0
            rowExpandable: (record) => record.prompts.length > 0,
          }}
          loading={isFetching}
          columns={columns}
          dataSource={listConcepts?.data?.data.map((item) => {
            return { ...item, key: item.id };
          })}
          paginationOptions={{
            total: listConcepts?.data?.total,
            defaultPageSize: params.limit,
            current: params?.page,
          }}
          handlePageChange={(pageChange) =>
            handlePageChange(pageChange, setParams)
          }
          handlePageSizeChange={(current, size) =>
            handlePageSizeChange(size, setParams)
          }
        />
      </div>
    </div>
  );
};
export default withPermissionCheck(ListConceptsContainer, {
  view: "list-concepts",
  create: "create-concept",
  detail: "detail-concept",
  delete: "delete-concept",
});
