import React, { useEffect } from "react";
import HeaderContent from "../../../components/HeaderContent";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import InputComponent from "../../../components/FormComponent/Input";
import { Col, Form, Row, Spin, message } from "antd";
import SelectComponent from "../../../components/FormComponent/Select";
import ButtonBase from "../../../components/Button";
import { useGetListPermissionQuery } from "../../../store/Settings/Permissions";
import InputPasswordComponent from "./../../../components/FormComponent/InputPassword/index";
import { useValidationRules } from "../../../constants/rules";
import {
  useGetAccountByIdQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
} from "../../../store/Account";
import withPermissionCheck from "../../../constants/PermissionHook";

const CreatEditAccountContainer = ({
  hasCreatePermission,
  hasEditPermission,
  hasDetailPermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams(); // Get id from URL
  const [form] = Form.useForm();

  useEffect(() => {
    if (!hasCreatePermission || !hasDetailPermission) {
      navigate("/403");
    }
  }, [hasCreatePermission, navigate, hasDetailPermission]);

  const { data: listPermissions } = useGetListPermissionQuery(undefined, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: detailAccount, isLoading: isLoadingDetail } =
    useGetAccountByIdQuery(id || "", {
      skip: !id,
      refetchOnMountOrArgChange: true,
    });

  const [createAccount, { isLoading: isLoadingCreate }] =
    useCreateAccountMutation();
  const [updateAccount, { isLoading: isLoadingUpdate }] =
    useUpdateAccountMutation();

  const dataRole = listPermissions?.data?.data?.map((item) => {
    return { value: item.id, label: item.name };
  });

  useEffect(() => {
    if (detailAccount?.data) {
      form.setFieldsValue({
        name: detailAccount?.data?.name,
        email: detailAccount?.data?.email,
        role_id: detailAccount?.data?.roles.map((role) => role.id) || [],
      });
    } else {
      form.resetFields();
    }
  }, [detailAccount, form]);
  const { MailRules, PasswordRules, ConfirmPasswordRules, InputTextRule } =
    useValidationRules();
  const onSubmitData = async (values) => {
    try {
      if (id) {
        await updateAccount({
          id: id,
          data: values,
        }).unwrap();
        message.success(t("setting.createSuccess"));
      } else {
        await createAccount(values).unwrap();
        message.success(t("setting.createSuccess"));
        form.resetFields();
      }
      navigate("/account");
    } catch (error) {
      if (error?.data?.email) {
        message.error(error?.data?.email?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };
  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={
            id ? t("account.editAccount") : t("account.creatNewAccount")
          }
          textPreviousPage={t("account.backToList")}
          className="ml-3"
          actionBack={() => {
            navigate("/account");
          }}
        />
      </div>

      <div className="pt-12 flex justify-center">
        {isLoadingDetail ? (
          <Spin></Spin>
        ) : (
          <Form form={form} onFinish={onSubmitData}>
            <div className="bg-white w-[760px] h-full px-12 pt-12">
              <Row>
                <Col span={8}>
                  {t("account.fullName")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                      InputTextRule,
                    ]}
                  >
                    <InputComponent />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={8}>
                  {t("auth.email")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item name="email" rules={MailRules}>
                    <InputComponent disabled={!!id} />
                  </Form.Item>
                </Col>
              </Row>
              {!id && (
                <>
                  <Row>
                    <Col span={8}>
                      {t("account.password")}
                      <span className="text-[#ff0000]">*</span>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="password"
                        rules={PasswordRules}
                        hasFeedback
                      >
                        <InputPasswordComponent />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={8}>
                      {t("account.confirmPassword")}
                      <span className="text-[#ff0000]">*</span>
                    </Col>
                    <Col span={16}>
                      <Form.Item
                        name="confirm_password"
                        dependencies={["password"]}
                        hasFeedback
                        rules={ConfirmPasswordRules}
                      >
                        <InputPasswordComponent />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col span={8}>
                  {t("account.role")}
                  <span className="text-[#ff0000]">*</span>
                </Col>
                <Col span={16}>
                  <Form.Item
                    name="role_id"
                    rules={[
                      {
                        required: true,
                        message: t("setting.pleaseEnterField"),
                      },
                    ]}
                  >
                    <SelectComponent options={dataRole} />
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <div className="flex justify-end mt-6">
              <ButtonBase
                className="w-[108px] h-[36px]"
                title={t("common.submit")}
                htmlType="submit"
                disabled={!hasEditPermission && id}
                loading={isLoadingUpdate || isLoadingCreate}
              />
            </div>
          </Form>
        )}
      </div>
    </div>
  );
};
export default withPermissionCheck(CreatEditAccountContainer, {
  create: "create-user",
  edit: "update-user",
  detail: "detail-user",
});
