import { Col, Divider, Modal, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { useGetReportInfoQuery } from "../../../store/Implement";
import { LIST_ANALYSIS_TARGET } from "../../../constants";

const ModalDetailConcept = (props) => {
  const { t } = useTranslation();
  const { modalDetail, setModalDetail } = props;
  const [detailConcept, setDetailConcept] = useState({});
  const { data: infoReport } = useGetReportInfoQuery(
    modalDetail?.data?.id || "",
    {
      skip: !modalDetail?.data?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (infoReport?.data) {
      setDetailConcept(infoReport?.data?.concept);
    }
  }, [infoReport]);

  return (
    <Modal
      open={modalDetail.isOpen}
      footer={true}
      width={1000}
      onCancel={() => setModalDetail({ data: [], isOpen: false })}
    >
      <div className="px-6 py-4">
        <div className="bg-white p-6">
          <div className="flex justify-center">
            <div className="flex justify-between w-[90%]">
              <div className="text-[13px]">
                <div style={{ padding: "20px" }}>
                  <Row gutter={[16, 8]}>
                    <Col span={6}>
                      <h3>ID</h3>
                    </Col>
                    <Col span={18}>
                      <h3>{modalDetail?.data?.id}</h3>
                    </Col>
                    <Col span={6}>
                      <h3>{t("concept.analysisTarget")}</h3>
                    </Col>
                    <Col span={18}>
                      <h3>
                        {
                          LIST_ANALYSIS_TARGET.find(
                            (item) => item.value === modalDetail?.data?.target
                          ).label
                        }
                      </h3>
                    </Col>
                    {modalDetail?.data?.target === "individual" && (
                      <>
                        <Col span={6}>
                          <h3>{t("history.staffName")}</h3>
                        </Col>
                        <Col span={18}>
                          <h3>{modalDetail?.data?.name}</h3>
                        </Col>
                      </>
                    )}
                    {detailConcept.name ? (
                      <>
                        <Col span={6}>
                          <h3>{t("company.companyName")}</h3>
                        </Col>
                        <Col span={18}>
                          <h3>{modalDetail?.data?.company?.name}</h3>
                        </Col>
                        <Divider />
                        <Col span={6}>
                          <h3>{t("history.concept")}</h3>
                        </Col>
                        <Col span={18}>
                          <h3>{detailConcept.name}</h3>
                        </Col>
                        <Divider />
                        <Col span={6} className="flex items-center">
                          <h3>{t("concept.scoringCriteria")}</h3>
                        </Col>
                        <Col span={18}>
                          <div className="whitespace-break-spaces">
                            {detailConcept.criteria}
                          </div>
                        </Col>
                        {detailConcept?.format_output && (
                          <>
                            <Divider />
                            <Col span={6} className="flex items-center">
                              <h3>{t("concept.formOutput")}</h3>
                            </Col>
                            <Col span={18}>
                              <div className="whitespace-break-spaces">
                                {detailConcept.format_output}
                              </div>
                            </Col>
                          </>
                        )}
                        <Divider />
                        <Col span={6} className="flex items-center">
                          <h3>{t("concept.prompt")}</h3>
                        </Col>
                        <Col span={18}>
                          <ul>
                            {detailConcept.prompts?.map((item) => (
                              <>
                                <h3 className="font-bold">
                                  {`${t("setting.title")}： ${item.title}`}
                                </h3>
                                <div
                                  key={item.id}
                                  className="whitespace-break-spaces"
                                >
                                  {item.prompt}
                                </div>
                              </>
                            ))}
                          </ul>
                        </Col>
                      </>
                    ) : (
                      <div className="flex justify-center">
                        <Spin></Spin>
                      </div>
                    )}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDetailConcept;
