import companyLogo from "./images/common/company-logo.png";
import arrowBack from "./images/common/arrow-back.svg";
import iconUser from "./images/common/icon-user.svg";
import iconListConcepts from "./images/common/icon-list-concepts.svg";
import iconListCompany from "./images/common/icon-list-company.svg";
import iconImplement from "./images/common/icon-implement.svg";
import iconHistory from "./images/common/icon-history.svg";
import iconUserActive from "./images/common/icon-user-active.svg";
import iconListConceptsActive from "./images/common/icon-list-concepts-active.svg";
import iconListCompanyActive from "./images/common/icon-list-company-active.svg";
import iconImplementActive from "./images/common/icon-implement-active.svg";
import iconHistoryActive from "./images/common/icon-history-active.svg";
const common = {
  companyLogo,
  arrowBack,
  iconUser,
  iconListConcepts,
  iconListCompany,
  iconImplement,
  iconHistory,
  iconUserActive,
  iconListConceptsActive,
  iconListCompanyActive,
  iconImplementActive,
  iconHistoryActive,
};
const images = {
  common,
};
export default images;
