import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../api/apiConfig';

export const apiAccountSlice = createApi({
  reducerPath: 'accountApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Account'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListAccounts: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/user`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Account', id })), { type: 'Account', id: 'LIST' }]
          : [{ type: 'Account', id: 'LIST' }],
    }),

    getAccountById: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/user/info/${id}`,
      providesTags: (result, error, id) => [{ type: 'Account', id }],
    }),

    createAccount: builder.mutation({
      query: (newAccount) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/user`,
        method: 'POST',
        body: newAccount,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'Account', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    updateAccount: builder.mutation({
      query: ({ id, ...updatedAccount }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/user/${id}`,
        method: 'PUT', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedAccount.data,
      }),
      invalidatesTags: (result, error, { id }) => result?  [{ type: 'Account', id }] : [],
    }),

    deleteAccount: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Account', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetListAccountsQuery,
  useGetAccountByIdQuery,
  useCreateAccountMutation,
  useUpdateAccountMutation,
  useDeleteAccountMutation,
} = apiAccountSlice;