import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../../api/apiConfig';

export const apiLogoutSlice = createApi({
  reducerPath: 'logoutApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    logout: builder.mutation({
      query: () => ({
        url: `/${process.env.REACT_APP_API_VERSION}/logout`,
        method: 'POST',
      }),
    }),

  }),
});

export const { useLogoutMutation } = apiLogoutSlice;