import { Modal, Spin } from "antd";
import React, { useEffect, useState } from "react";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";

import { useGetReportInfoQuery } from "../../../store/Implement";
import { calculateAverage } from "../../../constants/ultil";
import ChartsComponent from "../../../components/Chart";
import { LIST_ANALYSIS_TARGET } from "../../../constants";
import "../../Implement/index.scss";

const ModalDetailReport = (props) => {
  const { t } = useTranslation();
  const { modalDetail, setModalDetail } = props;
  const [dataChart, setDataChart] = useState({});
  const [lineData, setLineData] = useState({});

  const [averageScore, setAverageScore] = useState();
  const [typeChart, setTypeChart] = useState("bar");
  const [scoreScale, setScoreScale] = useState("");
  const { data: infoReport } = useGetReportInfoQuery(
    modalDetail?.data?.id || "",
    {
      skip: !modalDetail?.data?.id,
      refetchOnMountOrArgChange: true,
    }
  );

  useEffect(() => {
    if (infoReport?.data) {
      let average = calculateAverage(infoReport?.data?.charts?.datasets?.data);
      setAverageScore(average);
      setScoreScale(infoReport?.data?.score_scale);
    }
  }, [infoReport]);

  return (
    <Modal
      open={modalDetail.isOpen}
      footer={true}
      width={1200}
      onCancel={() => setModalDetail({ data: [], isOpen: false })}
    >
      <div className="px-6 py-4">
        <div className="bg-white p-6">
          <div className="flex justify-end mb-10">
            <ButtonBase
              title={t("report.exportResult")}
              className="!bg-white !text-[#0B0A0A] w-[155px] h-9"
              onClick={() => {
                const url = `/custom-report/${modalDetail?.data?.id}`;
                window.open(url, "_blank");
              }}
            />
          </div>
          <div className="flex justify-center">
            <div className="flex justify-between w-2/3 border-solid border-[1px] p-6">
              <div className="text-[13px]">
                <div className="flex">
                  <div className="font-bold">ID： </div>
                  <span>{modalDetail?.data?.id}</span>
                </div>
                <div className="flex my-2">
                  <div className="font-bold">
                    {t("concept.analysisTarget")}：
                  </div>
                  <span>
                    {
                      LIST_ANALYSIS_TARGET.find(
                        (item) => item.value === modalDetail?.data?.target
                      ).label
                    }
                  </span>
                </div>
                {modalDetail?.data?.target === "individual" && (
                  <div className="flex my-2">
                    <div className="font-bold">{t("history.staffName")}： </div>
                    <span>{modalDetail?.data?.name}</span>
                  </div>
                )}
                <div className="flex my-2">
                  <div className="font-bold">{t("company.companyName")}： </div>
                  <span>{modalDetail?.data?.company?.name}</span>
                </div>
              </div>
              {infoReport?.data?.charts?.labels.length > 0 && (
                <div>
                  <div className="text-[19px] font-bold">
                    {t("history.averageScore")}
                  </div>
                  <div className="text-[36.36px] font-bold my-1">
                    {averageScore}
                  </div>
                  <div className="text-[15px]">{`合計スコア: ${averageScore}/${scoreScale}`}</div>
                </div>
              )}
            </div>
          </div>
          {scoreScale && (
            <ChartsComponent
              setDataChart={setDataChart}
              dataReport={dataChart}
              infoReport={infoReport?.data}
              typeChart={typeChart}
              setTypeChart={setTypeChart}
              lineData={lineData}
              setLineData={setLineData}
              scoreScale={scoreScale}
            />
          )}
          {!scoreScale && !infoReport?.json && (
            <div className="flex justify-center">
              <div
                className="report-content w-2/3 mt-2"
                dangerouslySetInnerHTML={{
                  __html: infoReport?.data?.html,
                }}
              ></div>
            </div>
          )}
          {!scoreScale && !infoReport && (
            <div className="flex justify-center">
              <Spin></Spin>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default ModalDetailReport;
