import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonBase from "../../components/Button";
import FormCreateReport from "./FormCreateReport";
import {
  useCreateReportMutation,
  useLazyGetJobReportQuery,
  useLazyGetInfoReportQuery,
} from "../../store/Implement";
import { message, Spin, Progress } from "antd";
import { borderColorsChart, colorsChart } from "../../constants";
import { calculateAverage } from "../../constants/ultil";
import withPermissionCheck from "../../constants/PermissionHook";
import ChartsComponent from "../../components/Chart";
import "./index.scss";

const ImplementContainer = ({ hasCreatePermission }) => {
  const { t } = useTranslation();
  const [createReport] = useCreateReportMutation();
  const [isLoadingCreateReport, setLoadingCreateReport] = useState(false);
  const [jobId, setJobId] = useState(null);
  const [triggerGetJobStatus] = useLazyGetJobReportQuery();
  const [triggerGetInfoReport] = useLazyGetInfoReportQuery();
  const [dataReport, setDataReport] = useState([]);
  const [lineData, setLineData] = useState({});
  const [idReport, setIdReport] = useState();
  const [averageScore, setAverageScore] = useState();
  const [progress, setProgress] = useState(0);
  const [infoReport, setInfoReport] = useState({});
  const [typeChart, setTypeChart] = useState("bar");
  const [scoreScale, setScoreScale] = useState("");

  const onSubmitCreateReport = async (values) => {
    setLoadingCreateReport(true);
    setProgress(0); // Reset progress khi bắt đầu
    let payload = {
      target: values?.target,
      company_id: values?.company_id,
      concept_id: values?.concept_id,
      prompt_ids: values?.prompt_ids,
    };
    if (values?.target === "organization") {
      if (
        values?.company_persons?.length >= 1 &&
        values?.company_persons?.[0]
      ) {
        payload.company_persons = values.company_persons;
      }
    }
    if (values?.target === "hierarchy") {
      payload.hierarchy_id = values?.hierarchy_id;
    }
    if (values?.target === "individual") {
      payload.person_id = values?.person_id;
      payload.interview_id = values?.interview_id;
    }

    try {
      const res = await createReport(payload).unwrap();
      if (res.data) {
        setJobId(res.data?.job?.id);
        getPollJobStatus(res.data?.job?.id, res.data?.id);
      }
    } catch (error) {
      message.error(error?.data?.message);
      setLoadingCreateReport(false);
    }
  };

  const getPollJobStatus = (jobId, idReport) => {
    let pollingInterval;

    const fetchJobStatus = async () => {
      const { data: statusData } = await triggerGetJobStatus(jobId);
      if (statusData) {
        if (statusData?.data?.status !== "completed") {
          setProgress((prevProgress) => {
            if (prevProgress < 95) {
              return Math.min(prevProgress + 5, 95);
            }
            return 99;
          });

          // Nếu pendingJobs khác completed, tiếp tục gọi API sau 5 giây
          pollingInterval = setTimeout(fetchJobStatus, 5000);
        } else {
          await callFinalApi(idReport);
        }
      }
    };

    fetchJobStatus();

    return () => clearTimeout(pollingInterval);
  };

  const callFinalApi = async (idReport) => {
    try {
      const result = await triggerGetInfoReport(idReport).unwrap();
      if (result?.data) {
        setScoreScale(result?.data?.concept?.score_scale?.name);
        setInfoReport(result?.data);
        setLoadingCreateReport(false);
        setProgress(100); // Đặt tiến trình hoàn thành
        setIdReport(idReport);
        const radarData = {
          labels: result.data?.charts.labels,
          datasets: [
            {
              label: result.data?.charts.datasets?.label,
              data: result.data?.charts.datasets?.data.map(Number),
              backgroundColor: colorsChart.slice(
                0,
                result.data?.charts.labels.length
              ),
              borderColor: borderColorsChart.slice(
                0,
                result.data?.charts.labels.length
              ),
              borderWidth: 1,
            },
          ],
        };
        setDataReport(radarData);
        let average = calculateAverage(result.data?.charts?.datasets?.data);
        setAverageScore(average);
      }
    } catch (error) {
      message.error(error?.data?.message);
      setLoadingCreateReport(false);
    }
  };
  const handleOpenNewTab = () => {
    const url = `/custom-report/${idReport}`;
    window.open(url, "_blank");
  };

  return (
    <div>
      <FormCreateReport
        onSubmit={onSubmitCreateReport}
        hasCreatePermission={hasCreatePermission}
        isLoadingCreateReport={isLoadingCreateReport}
      />
      {jobId && !isLoadingCreateReport && (
        <div className="px-6 py-4">
          <div className="bg-white p-6">
            <div className="flex justify-end mb-10">
              <ButtonBase
                title={t("report.exportResult")}
                className="!bg-white !text-[#0B0A0A] w-[155px] h-9"
                onClick={handleOpenNewTab}
              />
            </div>
            {scoreScale && (
              <>
                <div className="flex justify-center">
                  <div className="w-2/3 border-solid border-[1px] p-6">
                    <div className="text-[19px] font-bold">
                      {t("history.averageScore")}
                    </div>
                    <div className="text-[36.36px] font-bold my-1">
                      {averageScore}
                    </div>
                    <div className="text-[15px]">{`合計スコア: ${averageScore}/${scoreScale}`}</div>
                  </div>
                </div>
                <ChartsComponent
                  setDataChart={setDataReport}
                  dataReport={dataReport}
                  infoReport={infoReport}
                  typeChart={typeChart}
                  setTypeChart={setTypeChart}
                  lineData={lineData}
                  setLineData={setLineData}
                  scoreScale={scoreScale}
                />
              </>
            )}
            {!scoreScale && !infoReport?.json && (
              <div
                className="report-content"
                dangerouslySetInnerHTML={{
                  __html: infoReport?.html,
                }}
              ></div>
            )}
          </div>
        </div>
      )}
      {jobId && isLoadingCreateReport && (
        <>
          <div className="flex justify-center items-center p-10">
            <Spin></Spin>
          </div>
          <div className="flex justify-center mb-4">
            <div className="w-1/3">
              <Progress
                percent={progress}
                status={progress === 100 ? "success" : "active"}
              />
            </div>
          </div>
          <div className="text-center font-medium text-lg text-[var(--primary-color)]">
            {t("employee.pleaseWait")}
          </div>
        </>
      )}
    </div>
  );
};

export default withPermissionCheck(ImplementContainer, {
  create: "create-report",
});
