import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../../api/apiConfig';

export const apiRoleSlice = createApi({
  reducerPath: 'roleApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Role'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListRole: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/roles/${id}`,
      providesTags: (result) =>{
        const data = Object.keys(result.data.permissions).map(key => ({ [key]: result.data.permissions[key] }));
        
        return result
          ? [...data.map(({ id }) => ({ type: 'Role', id })), { type: 'Role', id: 'LIST' }]
          : [{ type: 'Role', id: 'LIST' }]  
      }    
    }),
     getUserInfo: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/user/info`,
      providesTags: (result, error, id) => [{ type: 'Role', id }],
    }),

    updateRole: builder.mutation({
      query: ({ id, ...updatedPosition }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/roles/${id}`,
        method: 'PUT', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedPosition.data,
      }),
      invalidatesTags: (result, error, { id }) => result ?  [{ type: 'Role', id }] : [],
    }),

  }),
});

export const {
  useGetListRoleQuery,
  useGetUserInfoQuery,
  useUpdateRoleMutation,
} = apiRoleSlice;