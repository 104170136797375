import { Popconfirm } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";

const PopConfirmBase = (props) => {
  const { t } = useTranslation();
  const { children, ...otherProps } = props;
  return (
    <Popconfirm
      {...otherProps}
      cancelButtonProps={{
        style: { color: "black", backgroundColor: "white" },
      }}
      cancelText={t("common.cancel")}
      okText={t("common.ok")}
    >
      {children}
    </Popconfirm>
  );
};

export default PopConfirmBase;
