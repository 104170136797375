import React, { useEffect, useMemo, useState } from "react";
import HeaderContent from "../../../components/HeaderContent/index";
import ButtonBase from "../../../components/Button/index";
import CreateEditJobTitle from "./CreateEditJobTitle";
import TableBaseComponent from "../../../components/FormComponent/Table";
import {
  useGetListJobTitleQuery,
  useDeleteJobTitleMutation,
  useSwapMutation,
} from "../../../store/Settings/JobTitle";
import { useTranslation } from "react-i18next";
import { Spin, message } from "antd";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { MenuOutlined } from "@ant-design/icons";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import PopConfirmBase from "../../../components/PopupConfirm";
import {
  handlePageChange,
  handlePageSizeChange,
} from "../../../constants/ultil";
import withPermissionCheck from "../../../constants/PermissionHook";

export const RowContext = React.createContext();

const ListJobTitle = ({
  hasCreatePermission,
  hasDetailPermission,
  hasEditPermission,
  hasDeletePermission,
}) => {
  const { t } = useTranslation();
  const [isLoadingDrag, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    limit: 50,
    page: 1,
  });
  const [modalCreate, setModalCreate] = useState({
    data: {},
    isOpen: false,
  });
  const { data: listJobTitle, isFetching } = useGetListJobTitleQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [swapJobTitle] = useSwapMutation();
  const [deleteJobTitle] = useDeleteJobTitleMutation();

  const [items, setItems] = useState(listJobTitle?.data?.data || []);
  useEffect(() => {
    if (listJobTitle?.data?.data) {
      setItems(listJobTitle.data.data);
    }
  }, [listJobTitle]);
  const onDeleteJobTitle = async (record) => {
    try {
      await deleteJobTitle(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(error?.data?.message);
    }
  };

  const columns = [
    {
      title: "",
      key: "id",
      dataIndex: "id",
      width: 50,
      render: (_, record) => (
        <RowContext.Consumer>
          {({ setActivatorNodeRef, listeners }) => {
            return hasEditPermission ? (
              <div
                ref={setActivatorNodeRef} // Chỉ cho phép kéo bằng cột này
                {...listeners}
                style={{ cursor: "move" }}
              >
                <MenuOutlined />
              </div>
            ) : (
              <div>
                <MenuOutlined />
              </div>
            );
          }}
        </RowContext.Consumer>
      ),
    },
    {
      title: t("setting.title"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("setting.detail"),
      key: "description",
      dataIndex: "description",
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex text-[var(--primary-color)] cursor-pointer">
          <div
            className={`mr-5 text-[var(--primary-color)] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                setModalCreate({ data: record, isOpen: true });
              }
            }}
          >
            {t("setting.edit")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteJobTitle(record)}
            >
              <div className="font-medium text-[var(--primary-color)] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    })
  );

  const onDragEnd = async ({ active, over }) => {
    if (active.id && over?.id && active.id !== over?.id) {
      setIsLoading(true);
      try {
        // Cập nhật trạng thái items
        const newItems = [...items];
        const activeIndex = newItems.findIndex((item) => item.id === active.id);
        const overIndex = newItems.findIndex((item) => item.id === over.id);

        // Đổi chỗ phần tử
        const [movedItem] = newItems.splice(activeIndex, 1);
        newItems.splice(overIndex, 0, movedItem);

        setItems(newItems); // Cập nhật lại items
        await swapJobTitle({
          new_id: over.id,
          old_id: active.id,
        });
        message.success(t("setting.swapSuccess"));
      } catch (error) {
        message.error(error?.data?.message);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const RowCustom = (props) => {
    const {
      listeners,
      setNodeRef,
      transform,
      setActivatorNodeRef,
      transition,
      isDragging,
      attributes,
    } = useSortable({
      id: props["data-row-key"],
    });

    const contextValue = useMemo(
      () => ({ setActivatorNodeRef, listeners }),
      [setActivatorNodeRef, listeners]
    );

    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && { ...transform, scaleY: 1 }
      ),
      transition,
      ...(isDragging ? { position: "relative", zIndex: 1 } : {}),
    };

    return (
      <RowContext.Provider value={contextValue}>
        <tr {...props} ref={setNodeRef} style={style} {...attributes} />
      </RowContext.Provider>
    );
  };

  return (
    <Spin spinning={isLoadingDrag}>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("common.jobTitle")}
          className="ml-3"
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px]"
            title={t("setting.newCreate")}
            onClick={() => setModalCreate({ isOpen: true })}
            disabled={!hasCreatePermission}
          />
        </div>
      </div>
      <div className="p-4">
        <DndContext
          modifiers={[restrictToVerticalAxis]}
          sensors={sensors}
          onDragEnd={onDragEnd}
        >
          <SortableContext
            items={items?.map?.((i) => i?.id)}
            strategy={verticalListSortingStrategy}
          >
            <TableBaseComponent
              loading={isFetching}
              columns={columns}
              dataSource={items}
              rowKey="id"
              paginationOptions={{
                total: listJobTitle?.data?.total,
                defaultPageSize: params.limit,
                current: params?.page,
              }}
              handlePageChange={(pageChange) =>
                handlePageChange(pageChange, setParams)
              }
              handlePageSizeChange={(current, size) =>
                handlePageSizeChange(size, setParams)
              }
              components={{
                body: {
                  row: RowCustom,
                },
              }}
            />
          </SortableContext>
        </DndContext>
      </div>
      {modalCreate.isOpen && (
        <CreateEditJobTitle
          modalCreate={modalCreate}
          setModalCreate={setModalCreate}
          hasEditPermission={hasEditPermission}
        />
      )}
    </Spin>
  );
};

export default withPermissionCheck(ListJobTitle, {
  view: "list-job-titles",
  create: "create-job-title",
  detail: "detail-job-title",
  edit: "update-job-title",
  delete: "delete-job-title",
});
