import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../api/apiConfig';

export const apiConceptSlice = createApi({
  reducerPath: 'conceptApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Concept'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListConcepts: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Concept', id })), { type: 'Concept', id: 'LIST' }]
          : [{ type: 'Concept', id: 'LIST' }],
    }),

    getConceptById: builder.query({
      query: (id) => `/${process.env.REACT_APP_API_VERSION}/concept/${id}`,
      providesTags: (result, error, id) => [{ type: 'Concept', id }],
    }),

    createConcept: builder.mutation({
      query: (newConcept) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept`,
        method: 'POST',
        body: newConcept,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'Concept', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    updateConcept: builder.mutation({
      query: ({ id, ...updatedConcept }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept/${id}`,
        method: 'PUT', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedConcept.data,
      }),
      invalidatesTags: (result, error, { id }) => result ?  [{ type: 'Concept', id }] : [],
    }),

    copyConcept: builder.mutation({
      query: ( id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept/copy/${id}`,
        method: 'POST', // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Concept', id: 'LIST' }] : [],
    }),

    deleteConcept: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Concept', id: 'LIST' }] : [],
    }),
    deletePrompt: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/concept_prompt/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Concept', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetListConceptsQuery,
  useGetConceptByIdQuery,
  useCreateConceptMutation,
  useUpdateConceptMutation,
  useDeleteConceptMutation,
  useDeletePromptMutation,
  useCopyConceptMutation
} = apiConceptSlice;