import React, { useEffect } from "react";
import "./index.scss";
import { Form, message } from "antd";
import InputComponent from "../../../components/FormComponent/Input";
import ButtonBase from "../../../components/Button";
import { useValidationRules } from "../../../constants/rules";
import { useLoginMutation } from "../../../store/Auth/Login";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import InputPasswordComponent from "../../../components/FormComponent/InputPassword";
import { useDispatch } from "react-redux";
import { storeSetAuth } from "../../../store/authReducer";
import { renderConfigApp } from "../../../constants/ultil";
import { apiSlices } from "../../../store";

const LoginContainer = ({ listConfig }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { MailRules, PasswordRules } = useValidationRules();
  const [login, { isLoading }] = useLoginMutation();

  const onSubmitLogin = async (values) => {
    try {
      const userData = await login(values).unwrap();
      if (userData.data.token) {
        Cookies.set("accessToken", userData.data.token, { expires: 7 });
        dispatch(storeSetAuth(userData.data));
      }
    } catch (error) {
      if (error?.data?.email) {
        message.error(error?.data?.email?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };

  useEffect(() => {
    const token = Cookies.get("accessToken");
    if (!token) {
      apiSlices.forEach((slice) => {
        if (slice.reducerPath !== "siteConfigApi") {
          dispatch(slice.util.resetApiState());
        }
      });
      localStorage.removeItem("activeMenuKey");
      dispatch(storeSetAuth(null));
    }
  }, [dispatch]);

  return (
    <div className="bg-[#999999] min-h-screen">
      <div className="pl-10 py-[14px]">
        <img
          src={renderConfigApp(listConfig?.data, "vi", "site_logo")}
          width={215}
          alt="logo"
        />
      </div>
      <div className="flex justify-around">
        <div className="text-white text-[56px] font-bold flex items-center">
          {renderConfigApp(listConfig?.data, "vi", "site_title")}
        </div>
        <div className="bg-white rounded w-[375px] h-full mt-10 p-6">
          <div className="text-xl font-bold text-[var(--primary-color)] mb-[18px]">
            {`${renderConfigApp(listConfig?.data, "vi", "site_title")}${t(
              "auth.welcomBrandBook"
            )}`}
          </div>
          <div className="header-modal-login"></div>
          <Form className="mt-6" onFinish={onSubmitLogin}>
            <Form.Item name="email" rules={MailRules}>
              <InputComponent
                title={t("auth.email")}
                placeholder={t("auth.pleaseInputEmail")}
                required
              />
            </Form.Item>
            <Form.Item name="password" rules={PasswordRules}>
              <InputPasswordComponent
                title={t("account.password")}
                placeholder={t("auth.pleaseInputPassword")}
                required
              />
            </Form.Item>
            <ButtonBase
              title={t("auth.login")}
              htmlType="submit"
              className="w-[327px] h-11 mt-4"
              loading={isLoading}
            />
          </Form>
          <div className="text-[11px] mt-2 mb-6">
            <span>{t("auth.note1")}</span>
            <a href="/" className="text-[var(--primary-color)]">
              {t("auth.termOfUse")}
            </a>
            <span>{t("auth.note2")}</span>
          </div>
          <div
            className="text-[13px] text-[var(--primary-color)] cursor-pointer"
            onClick={() => navigate("/auth/forgot-password")}
          >
            {t("auth.forgotPassword")}
          </div>
          <div className="header-modal-login mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default LoginContainer;
