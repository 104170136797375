import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiDepartmentSlice = createApi({
  reducerPath: "departmentApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["Department"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListDepartments: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/department`,
        params: params,
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({ type: "Department", id })),
              { type: "Department", id: "LIST" },
            ]
          : [{ type: "Department", id: "LIST" }],
    }),

    createDepartment: builder.mutation({
      query: (newDepartment) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/department`,
        method: "POST",
        body: newDepartment,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "Department", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateDepartment: builder.mutation({
      query: ({ id, ...updatedDepartment }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/department/${id}`,
        method: "PUT",
        body: updatedDepartment.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "Department", id }] : [],
    }),

    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/department/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "Department", id: "LIST" }],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/department/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "Department", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListDepartmentsQuery,
  useCreateDepartmentMutation,
  useUpdateDepartmentMutation,
  useDeleteDepartmentMutation,
  useSwapMutation,
} = apiDepartmentSlice;
