import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { message } from 'antd';
import Cookies from "js-cookie";

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_DOMAIN,
  prepareHeaders: (headers, { getState, endpoint, type }) => {
    // Lấy token từ localStorage hoặc Redux state
    headers.set('Locale', 'ja')
    const token = Cookies.get('accessToken');
    const UPLOAD_ENDPOINTS = ['createEmployee', 'updateEmployee', 'uploadFile']
    if(!UPLOAD_ENDPOINTS.includes(endpoint)){
      headers.set('Content-Type', 'application/json');
    }
    
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    
    return headers;
  },
});

// Hàm baseQueryWithErrorHandling để xử lý lỗi
const baseQueryWithErrorHandling = async (args, api, extraOptions) => { 
  const result = await baseQuery(args, api, extraOptions);

  if (result.error) {
    if (result.error.status === 401) {
      window.location.replace('/403')
    } else if (result.error.status === 500) {
      message.error("システムエラー!");

    } 
  }

  return result;
};

export default baseQueryWithErrorHandling;