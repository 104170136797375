import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import InputComponent from "../../../components/FormComponent/Input";
import { Button, Col, Form, Row, message, Input } from "antd";
import dayjs from "dayjs";
import { MinusCircleOutlined } from "@ant-design/icons";
import SelectComponent from "../../../components/FormComponent/Select";
import ButtonBase from "../../../components/Button";
import { useGetListIndustryQuery } from "../../../store/Settings/Industry";
import { useGetListCompanyMissionVisionValueQuery } from "../../../store/Settings/CompanyMissionVisionValue";
import {
  useCreateCompanyMutation,
  useUpdateCompanyMutation,
} from "../../../store/Company";
import { useValidationRules } from "../../../constants/rules";

const { TextArea } = Input;

const FormCreateEditCompany = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { detailCompany, idCompany, hasEditPermission } = props;
  const { InputTextRule } = useValidationRules();

  const [listMaster, setListMaster] = useState([
    {
      key: dayjs().valueOf(),
      company_mission_vision_value_id: "",
      content: "",
    },
  ]);
  const [params] = useState({
    limit: 50,
    page: 1,
  });
  const [selectedValues, setSelectedValues] = useState([]);
  const { data: listIndustry } = useGetListIndustryQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  const { data: listCompanyMissionVisionValue } =
    useGetListCompanyMissionVisionValueQuery(params, {
      refetchOnMountOrArgChange: true, // auto call API when component mount
    });
  const [createCompany, { isLoading: isLoadingCreate }] =
    useCreateCompanyMutation();
  const [updateCompany, { isLoading: isLoadingUpdate }] =
    useUpdateCompanyMutation();
  useEffect(() => {
    if (detailCompany) {
      const listMasterMission =
        detailCompany?.data?.company_mission_vision_value?.map((item) => ({
          ...item,
          key: item.id,
        }));
      setSelectedValues(
        detailCompany?.data?.company_mission_vision_value?.map(
          (item) => item.id
        )
      );
      if (listMasterMission.length > 0) {
        setListMaster(listMasterMission);
      }

      form.setFieldsValue({
        name: detailCompany.data?.name,
        address: detailCompany.data?.address,
        industry_id: detailCompany.data?.industry_id,
        number_of_employee: detailCompany.data?.number_of_employee,
        ...listMasterMission.reduce(
          (acc, record, _) => ({
            ...acc,
            [`missionVision-${record.id}`]: record.id,
            [`content-${record.id}`]: record.content,
          }),
          {}
        ),
      });
    }
  }, [detailCompany, form]);

  const handleAddMaster = () => {
    setListMaster([
      ...listMaster,
      {
        key: dayjs().valueOf(),
        company_mission_vision_value_id: "",
        content: "",
      },
    ]);
  };
  const handleRemoveMaster = (indexToRemove, item) => {
    const newSelectedValues = [...selectedValues];
    const newList = newSelectedValues.filter(
      (id) => id !== item.company_mission_vision_value_id
    );
    setSelectedValues(newList);
    let array = [...listMaster];
    const updatedListMaster = array.filter(
      (_, index) => index !== indexToRemove
    );

    setListMaster(updatedListMaster);
  };

  const onSubmitData = async (values) => {
    const output = [];

    Object.keys(values).forEach((key) => {
      const [type, id] = key.split("-"); // Tách key thành 2 phần: type (content hoặc missionVision) và id

      // Nếu key là 'content', ta tìm 'missionVision' có cùng id
      if (type === "content") {
        const content = values[key];
        const missionVisionKey = `missionVision-${id}`;
        const missionVision = values[missionVisionKey];

        if (missionVision !== undefined) {
          // Gom cặp { content, missionVision } vào một object và đẩy vào array output
          output.push({
            content,
            company_mission_vision_value_id: missionVision,
          });
        }
      }
    });
    let payload = {
      name: values?.name,
      address: values?.address,
      industry_id: values?.industry_id,
      number_of_employee: values?.number_of_employee,
      company_mission_vision_value: output,
    };
    try {
      if (idCompany) {
        await updateCompany({
          id: idCompany,
          data: payload,
        }).unwrap();
        message.success(t("setting.createSuccess"));
      } else {
        await createCompany(payload).unwrap();
        message.success(t("setting.createSuccess"));
        form.resetFields();
      }
      navigate("/list-company");
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const handleSelectChange = (value, index) => {
    let newSelectedValues = [...selectedValues];
    newSelectedValues[index] = value;
    setSelectedValues(newSelectedValues);
    let array = [...listMaster];
    array[index]["company_mission_vision_value_id"] = value;
    setListMaster(array);
  };

  return (
    <div className="flex justify-center">
      <Form form={form} onFinish={onSubmitData}>
        <div className="bg-white w-[760px] h-full px-12 pt-12">
          <Row>
            <Col span={8}>
              {t("company.companyName")}
              <span className="text-[#ff0000]">*</span>
            </Col>
            <Col span={16}>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: t("setting.pleaseEnterField"),
                  },
                  InputTextRule,
                ]}
              >
                <InputComponent />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>{t("company.address")}</Col>
            <Col span={16}>
              <Form.Item name="address" rules={[InputTextRule]}>
                <InputComponent />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={8}>{t("common.industry")}</Col>
            <Col span={16}>
              <Form.Item name="industry_id">
                <SelectComponent
                  options={listIndustry?.data?.data?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={8}>{t("company.numberOfEmployee")}</Col>
            <Col span={16}>
              <Form.Item name="number_of_employee">
                <InputComponent />
              </Form.Item>
            </Col>
          </Row>

          {listMaster.map((item, index) => {
            return (
              <div key={item.key}>
                <Row>
                  {index === 0 ? (
                    <Col span={8}>{t("company.master")}</Col>
                  ) : (
                    <Col span={8}></Col>
                  )}
                  <Col span={16} className="relative">
                    <Form.Item name={`missionVision-${item.key}`}>
                      <SelectComponent
                        options={listCompanyMissionVisionValue?.data?.data?.map(
                          (item) => ({
                            label: item.name,
                            value: item.id,
                            disabled: selectedValues.includes(item.id),
                          })
                        )}
                        onChange={(value) => handleSelectChange(value, index)}
                      />
                    </Form.Item>
                    <div className="absolute top-1 right-[-30px]">
                      <Button
                        icon={<MinusCircleOutlined />}
                        type="link"
                        danger
                        onClick={() => handleRemoveMaster(index, item)}
                        disabled={listMaster.length === 1}
                      />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={8}></Col>
                  <Col span={16}>
                    <Form.Item name={`content-${item.key}`}>
                      <TextArea
                        rows={4}
                        className="hover:!border-[var(--primary-color)]"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            );
          })}
          <Row>
            <Col span={8}></Col>
            <Col span={16} className="mt-[-15px]">
              <Button
                type="text"
                onClick={handleAddMaster}
                disabled={
                  listCompanyMissionVisionValue?.data?.data.length ===
                  listMaster.length
                }
              >
                + <div className="ml-2">{t("setting.create")}</div>
              </Button>
            </Col>
          </Row>
        </div>
        <div className="flex justify-end mt-6">
          <ButtonBase
            className="w-[108px] h-[36px]"
            title={t("common.submit")}
            htmlType="submit"
            disabled={!hasEditPermission && idCompany}
            loading={isLoadingUpdate || isLoadingCreate}
          />
        </div>
      </Form>
    </div>
  );
};

export default FormCreateEditCompany;
