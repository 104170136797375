import { useEffect } from "react";
import { Spin } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useGetUserInfoQuery } from "../store/Settings/Role";

const withPermissionCheck = (WrappedComponent, permissionRole) => {
  return (props) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { data: userInfo, isLoading: isLoadingUserInfo } =
      useGetUserInfoQuery(undefined, {
        refetchOnMountOrArgChange: true,
      });
    const hasCreatePermission = userInfo?.data?.list_permission.includes(
      permissionRole.create
    );
    const hasDetailPermission = userInfo?.data?.list_permission.includes(
      permissionRole.detail
    );
    const hasEditPermission = userInfo?.data?.list_permission.includes(
      permissionRole.edit
    );
    const hasDeletePermission = userInfo?.data?.list_permission.includes(
      permissionRole.delete
    );

    useEffect(() => {
      if (!isLoadingUserInfo && userInfo) {
        if (
          permissionRole.view &&
          !userInfo.data?.list_permission.includes(permissionRole.view)
        ) {
          navigate("/403");
        }
      }
    }, [isLoadingUserInfo, userInfo, navigate, t]);

    if (isLoadingUserInfo) {
      return (
        <div className="flex justify-center">
          <Spin />
        </div>
      );
    }

    return (
      <WrappedComponent
        {...props}
        hasCreatePermission={hasCreatePermission}
        hasEditPermission={hasEditPermission}
        hasDeletePermission={hasDeletePermission}
        hasDetailPermission={hasDetailPermission}
      />
    );
  };
};

export default withPermissionCheck;
