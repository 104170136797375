import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authInfo: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeSetAuth: (state, { payload }) => {
      state.authInfo = payload;
    },
  },
});

export const { storeSetAuth } = authSlice.actions;

export default authSlice.reducer;
