import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiPositionSlice = createApi({
  reducerPath: "positionApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["Position"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListPositions: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/position`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({ type: "Position", id })),
              { type: "Position", id: "LIST" },
            ]
          : [{ type: "Position", id: "LIST" }],
    }),

    createPosition: builder.mutation({
      query: (newPosition) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/position`,
        method: "POST",
        body: newPosition,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "Position", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updatePosition: builder.mutation({
      query: ({ id, ...updatedPosition }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/position/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedPosition.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "Position", id }] : [],
    }),

    deletePosition: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/position/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "Position", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/position/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "Position", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListPositionsQuery,
  useCreatePositionMutation,
  useUpdatePositionMutation,
  useDeletePositionMutation,
  useSwapMutation,
} = apiPositionSlice;
