import { DatePicker } from "antd";
import React from "react";

const DatePickerComponent = (props) => {
  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div>
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <DatePicker
        className={`${className} w-full h-10 focus:!border-[var(--primary-color)] hover:!border-[var(--primary-color)]`}
        {...otherProps}
        format={"YYYY/MM/DD"}
      />
    </div>
  );
};
export default DatePickerComponent;
