import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiIndustrySlice = createApi({
  reducerPath: "industryApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["Industry"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListIndustry: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/industry`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({ type: "Industry", id })),
              { type: "Industry", id: "LIST" },
            ]
          : [{ type: "Industry", id: "LIST" }],
    }),

    createIndustry: builder.mutation({
      query: (newIndustry) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/industry`,
        method: "POST",
        body: newIndustry,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "Industry", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateIndustry: builder.mutation({
      query: ({ id, ...updatedIndustry }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/industry/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedIndustry.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "Industry", id }] : [],
    }),

    deleteIndustry: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/industry/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "Industry", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/industry/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "Industry", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListIndustryQuery,
  useCreateIndustryMutation,
  useUpdateIndustryMutation,
  useDeleteIndustryMutation,
  useSwapMutation,
} = apiIndustrySlice;
