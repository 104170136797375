import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiCompanyHierarchySlice = createApi({
  reducerPath: "CompanyHierarchyApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["CompanyHierarchy"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListCompanyHierarchy: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company_hierarchy`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: "CompanyHierarchy",
                id,
              })),
              { type: "CompanyHierarchy", id: "LIST" },
            ]
          : [{ type: "CompanyHierarchy", id: "LIST" }],
    }),

    createCompanyHierarchy: builder.mutation({
      query: (newCompanyHierarchy) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company_hierarchy`,
        method: "POST",
        body: newCompanyHierarchy,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "CompanyHierarchy", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateCompanyHierarchy: builder.mutation({
      query: ({ id, ...updatedCompanyHierarchy }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company_hierarchy/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedCompanyHierarchy.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "CompanyHierarchy", id }] : [],
    }),

    deleteCompanyHierarchy: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company_hierarchy/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "CompanyHierarchy", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/company_hierarchy/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "CompanyHierarchy", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListCompanyHierarchyQuery,
  useCreateCompanyHierarchyMutation,
  useUpdateCompanyHierarchyMutation,
  useDeleteCompanyHierarchyMutation,
  useSwapMutation,
} = apiCompanyHierarchySlice;
