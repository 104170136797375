import { Select, Empty } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
const SelectComponent = (props) => {
  const { t } = useTranslation();

  const { className, title, required, ...otherProps } = props;
  return (
    <div>
      <div>
        {title}
        {required && <span className="text-[#ff0000]">*</span>}
      </div>
      <Select
        notFoundContent={
          <Empty
            description={t("common.noData")}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        }
        className={`${className} h-full hover:!border-[var(--primary-color)] focus:!border-[var(--primary-color)] select-component`}
        {...otherProps}
      />
    </div>
  );
};
export default SelectComponent;
