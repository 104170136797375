import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../api/apiConfig';

export const apiSiteConfigSlice = createApi({
  reducerPath: 'siteConfigApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['SiteConfig'], // Tag để invalidate cache cho các thao tác như create, update, delete
   endpoints: (builder) => ({

    getListConfig: builder.query({
      query: () => ({
        url: `/${process.env.REACT_APP_API_VERSION}/site_config`,
      }),
      providesTags: (result) => {
        return result
          ? [...result.data?.map(({ id }) => ({ type: 'SiteConfig', id })), { type: 'SiteConfig', id: 'LIST' }]
          : [{ type: 'SiteConfig', id: 'LIST' }]
      }
    }),


   createSiteConfig: builder.mutation({
      query: (newSite) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/site_config`,
        method: 'POST',
        body: newSite,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'SiteConfig', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    uploadFile: builder.mutation({
      query: (newFile) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/file/upload`,
        method: 'POST',
        body: newFile,
      }),
      invalidatesTags:  (result, error) => {
        return []
      }
    }),

  }),
});

export const {
  useGetListConfigQuery,
  useCreateSiteConfigMutation,
  useUploadFileMutation
} = apiSiteConfigSlice;