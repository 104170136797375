import React from "react";
import { Form, message } from "antd";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { useValidationRules } from "../../../constants/rules";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputPasswordComponent from "../../../components/FormComponent/InputPassword";
import { useChangePasswordMutation } from "../../../store/Auth/ChangePassword";
import { renderConfigApp } from "../../../constants/ultil";

const ChangePasswordContainer = ({ listConfig }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const email = searchParams.get("email");
  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const { PasswordRules, ConfirmPasswordRules } = useValidationRules();
  const onSubmitChangePass = async (values) => {
    const payload = {
      email,
      token,
      password: values.password,
      confirm_password: values.confirmPassword,
    };
    try {
      const res = await changePassword(payload);
      if (res.data?.success) {
        message.success(t("auth.alertChangePassSuccess"));
        navigate("/auth/login");
      } else {
        message.error(t("auth.invalidToken"));
      }
    } catch (error) {
      if (error?.data?.email) {
        message.error(error?.data?.email?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };
  return (
    <div className="bg-[#999999] min-h-screen">
      <div className="pl-10 py-[14px]">
        <img
          src={renderConfigApp(listConfig?.data, "vi", "site_logo")}
          width={215}
          alt="logo"
        />
      </div>
      <div className="flex justify-around">
        <div className="text-white text-[56px] font-bold flex items-center">
          {renderConfigApp(listConfig?.data, "vi", "site_title")}
        </div>
        <div className="bg-white rounded w-[375px] h-full mt-10 p-6">
          <div className="text-xl font-bold text-[var(--primary-color)] mb-[16px]">
            {t("auth.restPassword")}
          </div>
          <div className="header-modal-login"></div>
          <Form className="mt-6" onFinish={onSubmitChangePass}>
            <Form.Item name="password" rules={PasswordRules} hasFeedback>
              <InputPasswordComponent
                title={t("auth.newPassword")}
                placeholder={t("auth.pleaseInputNewPassword")}
                required
              />
            </Form.Item>
            <div className="text-[11px] text-[#655f5f] mt-[-20px] mb-3">
              <div>{t("auth.noteAboutPassword")}</div>
              <div>{t("auth.validatePassword1")}</div>
              <div>{t("auth.validatePassword2")}</div>
              <div>{t("auth.validatePassword3")}</div>
            </div>
            <Form.Item
              name="confirmPassword"
              dependencies={["password"]}
              hasFeedback
              rules={ConfirmPasswordRules}
            >
              <InputPasswordComponent
                title={t("auth.confirmPassword")}
                placeholder={t("auth.pleaseInputPassword")}
                required
              />
            </Form.Item>
            <div className="text-[11px] text-[#655f5f] mt-[-20px]">
              {t("auth.pleaseInputAgain")}
            </div>

            <ButtonBase
              title={t("auth.changePassword")}
              htmlType="submit"
              className="w-[327px] h-11 mt-4"
              loading={isLoading}
            />
          </Form>
          <div className="header-modal-login mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordContainer;
