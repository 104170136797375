import React, { useEffect } from "react";
import { Bar, Line, Pie, Radar, PolarArea, Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import SelectComponent from "../FormComponent/Select";
import { borderColorsChart, colorsChart } from "../../constants";
import { Spin } from "antd";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  RadarController,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  RadialLinearScale,
  RadarController,
  Title,
  Tooltip,
  Legend,
  Filler
);

export const LIST_CHART = [
  {
    value: "bar",
    label: "Bar Chart",
  },
  {
    value: "radar",
    label: "Radar Chart",
  },
  {
    value: "pie",
    label: "Pie Chart",
  },
  {
    value: "line",
    label: "Line Chart",
  },
  {
    value: "polarArea",
    label: "PolarArea Chart",
  },
  {
    value: "doughnut",
    label: "Doughnut Chart",
  },
];
const ChartsComponent = (props) => {
  const { t } = useTranslation();
  const {
    setDataChart,
    dataReport,
    infoReport,
    typeChart,
    setTypeChart,
    canvasRef,
    hiddenChart = false,
    lineData,
    setLineData,
    scoreScale,
  } = props;

  useEffect(() => {
    if (infoReport) {
      if (typeChart === "radar") {
        const radarData = {
          labels: infoReport?.charts?.labels,
          datasets: [
            {
              label: infoReport?.charts?.datasets?.label,
              data: infoReport?.charts?.datasets?.data?.map(Number),
              backgroundColor: colorsChart[0],
              borderColor: borderColorsChart[0],
              borderWidth: 1,
              fill: true,
              pointRadius: 5, // Kích thước điểm tròn trong Radar Chart
              pointHoverRadius: 8, // Kích thước khi hover
            },
          ],
        };

        setDataChart(radarData);
      } else if (typeChart === "line") {
        const lineChart = {
          labels: infoReport?.charts?.labels,
          datasets: [
            {
              label: infoReport?.charts?.datasets?.label,
              data: infoReport?.charts?.datasets?.data?.map(Number),
              borderColor: "#DE061A",
              backgroundColor: "rgba(75, 192, 192, 0.2)",
              pointRadius: 10,
              pointHoverRadius: 12,
              pointBackgroundColor: colorsChart.slice(
                0,
                infoReport?.charts?.labels?.length
              ),
              pointBorderColor: "#333333",
            },
          ],
        };
        setLineData(lineChart);
      } else {
        const radarData = {
          labels: infoReport?.charts?.labels,
          datasets: [
            {
              label: infoReport?.charts?.datasets?.label,
              data: infoReport?.charts?.datasets?.data?.map(Number),
              backgroundColor: colorsChart.slice(
                0,
                infoReport?.charts?.labels?.length
              ),
              borderColor: borderColorsChart.slice(
                0,
                infoReport?.charts?.labels?.length
              ),
              borderWidth: 1,
            },
          ],
        };
        setDataChart(radarData);
      }
    }
  }, [infoReport, typeChart, setDataChart, setLineData]);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        max: Number(scoreScale ?? 100),
      },
    },
  };
  const optionsRadar = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Hiển thị phần legend
      },
    },
    scales: {
      r: {
        angleLines: {
          display: true,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        beginAtZero: true,
        max: Number(scoreScale ?? 100), // Cố định giá trị tối đa trục
      },
    },
  };

  const optionsPieDoughnut = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
        display: false,
      },
      tooltip: {
        enabled: true,
      },
    },
  };

  return (
    <>
      {!hiddenChart && (
        <div className="flex justify-around my-4 text-xl font-bold">
          <div>{t("history.ScoringByItem")}</div>
          <SelectComponent
            className="w-[150px]"
            options={LIST_CHART}
            value={typeChart}
            onChange={(e) => {
              setTypeChart(e);
            }}
          />
        </div>
      )}

      <div className={`flex justify-center ${hiddenChart ? "invisible" : ""}`}>
        {dataReport?.datasets && dataReport.datasets?.length > 0 && (
          <div className="w-3/4 ml-[-30px]">
            {typeChart === "bar" && (
              <Bar
                data={dataReport}
                options={options}
                className="!w-full"
                ref={canvasRef}
              />
            )}

            {typeChart === "polarArea" && (
              <PolarArea
                data={dataReport}
                ref={canvasRef}
                options={optionsRadar}
                className="!w-full !h-full"
              />
            )}
            {typeChart === "pie" && (
              <Pie
                data={dataReport}
                options={optionsPieDoughnut}
                ref={canvasRef}
              />
            )}
            {typeChart === "doughnut" && (
              <Doughnut
                data={dataReport}
                options={optionsPieDoughnut}
                ref={canvasRef}
              />
            )}
            {typeChart === "radar" && (
              <Radar
                data={dataReport}
                options={optionsRadar}
                ref={canvasRef}
                className="!w-full !h-full"
              />
            )}
          </div>
        )}
        {lineData?.datasets &&
          lineData.datasets?.length > 0 &&
          typeChart === "line" && (
            <Line data={lineData} options={options} ref={canvasRef} />
          )}
        {!dataReport?.datasets && !lineData?.datasets && <Spin></Spin>}
      </div>
    </>
  );
};

export default ChartsComponent;
