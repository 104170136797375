import { Button, Col, Form, Row, Upload, Input } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./index.scss";
import ButtonBase from "../../../../components/Button";
import InputComponent from "../../../../components/FormComponent/Input";
import SelectComponent from "../../../../components/FormComponent/Select";
import DatePickerComponent from "../../../../components/FormComponent/DatePicker";
import { useGetListCompanyHierarchyQuery } from "../../../../store/Settings/CompanyHierarchy";
import { useGetListPositionsQuery } from "../../../../store/Settings/Positions";
import { useGetListDepartmentsQuery } from "../../../../store/Settings/Departments";
import { useGetListJobTitleQuery } from "../../../../store/Settings/JobTitle";
import { useGetListEmploymentTypeQuery } from "../../../../store/Settings/EmploymentType";
import { useValidationRules } from "../../../../constants/rules";

const { TextArea } = Input;

const FormCreateEmployee = (props) => {
  const { t } = useTranslation();
  const {
    form,
    onSubmit,
    handleRemoveInterview,
    handleSectionChange,
    handleAddSection,
    listInterviews,
    modalCreate,
    hasEditPermission,
    isProgress,
  } = props;
  const { InputTextRule } = useValidationRules();

  const [params] = useState({
    limit: 50,
    page: 1,
  });

  const { data: listHierarchy } = useGetListCompanyHierarchyQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listPosition } = useGetListPositionsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listDepartment } = useGetListDepartmentsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listJobType } = useGetListJobTitleQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listEmploymentType } = useGetListEmploymentTypeQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const checkDisabledUpload = (section) => {
    if (hasEditPermission) {
      if (section.id) {
        return true;
      }
      return false;
    }
    return false;
  };
  return (
    <Form form={form} className="form-create-department" onFinish={onSubmit}>
      <Row gutter={[8, 8]} className="bg-[#fdfdfd] form-info-employee p-4">
        <Col span={12}>
          <Form.Item
            name="name"
            rules={[
              { required: true, message: t("setting.pleaseEnterField") },
              InputTextRule,
            ]}
          >
            <InputComponent title={t("account.fullName")} required />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="date_of_birth"
            //   rules={[
            //     { required: true, message: t("setting.pleaseEnterField") },
            //   ]}
          >
            <DatePickerComponent title={t("employee.dateOfBirth")} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="hierarchy_id">
            <SelectComponent
              allowClear
              title={t("common.hierarchy")}
              options={listHierarchy?.data?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="position_id"
            //   rules={[
            //     { required: true, message: t("setting.pleaseEnterField") },
            //   ]}
          >
            <SelectComponent
              allowClear
              title={t("employee.position")}
              options={listPosition?.data?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="department_id">
            <SelectComponent
              allowClear
              title={t("common.department")}
              options={listDepartment?.data?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="job_title_id"
            //   rules={[
            //     { required: true, message: t("setting.pleaseEnterField") },
            //   ]}
          >
            <SelectComponent
              allowClear
              title={t("common.jobTitle")}
              options={listJobType?.data?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name="employment_type_id">
            <SelectComponent
              allowClear
              title={t("common.employmentType")}
              options={listEmploymentType?.data?.data?.map((item) => ({
                label: item.name,
                value: item.id,
              }))}
            />
          </Form.Item>
        </Col>
      </Row>
      <div className="mt-4 mb-[6px] font-semibold">
        {t("employee.videoAudio")}
      </div>
      {listInterviews?.map((section, index) => (
        <div
          key={section.key}
          style={{
            marginBottom: "10px",
            paddingBottom: "10px",
          }}
        >
          <Row>
            <Col span={12}>
              <Form.Item>
                <Upload
                  accept=".mp3, .mp4"
                  maxCount={1}
                  beforeUpload={(file) => {
                    handleSectionChange(index, "file", file);
                    return false;
                  }}
                  onRemove={() => {
                    handleSectionChange(index, "file", null); // Xóa file
                  }}
                  className="w-full"
                >
                  <ButtonBase
                    title={t("employee.upload")}
                    className="!bg-white !text-[#0B0A0A] w-full h-10"
                    disabled={checkDisabledUpload(section)}
                  />
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Button
                icon={<MinusCircleOutlined />}
                type="link"
                danger
                onClick={() => handleRemoveInterview(index, section)}
                disabled={listInterviews.length === 1}
              />
            </Col>
          </Row>

          <Form.Item
            name={`title-${section.key}`}
            rules={[
              // Rule required chỉ áp dụng khi có file được upload
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (section.file && !value) {
                    return Promise.reject(
                      new Error(t("setting.pleaseEnterField"))
                    );
                  }
                  return Promise.resolve();
                },
              }),
              InputTextRule,
            ]}
          >
            <InputComponent
              disabled={!section.file && !section.id}
              placeholder={t("employee.enterTitle")}
              value={section.title}
              onChange={(e) =>
                handleSectionChange(index, "title", e.target.value)
              }
            />
          </Form.Item>
          {modalCreate?.data?.id &&
            modalCreate?.data?.interviews?.length > 0 &&
            !section.isNew && (
              <Form.Item name={`segments-${section.key}`}>
                <TextArea
                  defaultValue={section.segments}
                  rows={4}
                  className="hover:!border-[var(--primary-color)]"
                  onChange={(e) =>
                    handleSectionChange(index, "segments", e.target.value)
                  }
                />
              </Form.Item>
            )}
        </div>
      ))}

      <ButtonBase
        type="dashed"
        onClick={handleAddSection}
        icon={<PlusOutlined />}
        className="!bg-white !text-[#0B0A0A] w-full"
      >
        ＋{t("setting.create")}
      </ButtonBase>
      <div className="flex justify-end mt-4">
        <ButtonBase
          loading={isProgress}
          title={t("common.submit")}
          htmlType="submit"
          disabled={!hasEditPermission && modalCreate?.data?.id}
        />
      </div>
    </Form>
  );
};

export default FormCreateEmployee;
