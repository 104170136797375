import { Layout } from "antd";
import "./App.scss";
import { Routes, Route, Navigate } from "react-router-dom";
import MenuBar from "./components/SideBar/MenuBar";
import LoginScreen from "./pages/Auth/Login";
import ForgotPasswordScreen from "./pages/Auth/ForgotPassword";
import ChangePasswordScreen from "./pages/Auth/ChangePassword";
import Cookies from "js-cookie";
import HeaderBar from "./components/SideBar/HeaderBar";
import { useEffect, useState } from "react";
import { MENU_ROUTES } from "./constants";
import Page404 from "./pages/404";
import CustomReportPage from "./pages/CustomReport";
import { useGetListConfigQuery } from "./store/SiteConfig";
import { renderConfigApp } from "./constants/ultil";

const { Header, Content, Sider } = Layout;

function App() {
  const [accessToken, setAccessToken] = useState(Cookies.get("accessToken"));
  const { data: listConfig } = useGetListConfigQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    const configTitle = renderConfigApp(listConfig?.data, "vi", "site_title");
    const configFavicon = renderConfigApp(
      listConfig?.data,
      "vi",
      "site_favicon"
    );
    if (configTitle) {
      document.title = configTitle;
    }
    if (configFavicon) {
      const link = document.querySelector('link[rel="icon"]');
      if (link) {
        link.href = configFavicon;
      } else {
        const newLink = document.createElement("link");
        newLink.rel = "icon";
        newLink.href = configFavicon;
        document.head.appendChild(newLink);
      }
    }
  }, [listConfig]);

  useEffect(() => {
    const handleCookieChange = () => {
      const token = Cookies.get("accessToken");
      setAccessToken(token);
    };
    const intervalId = setInterval(handleCookieChange, 1000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Layout>
      {accessToken ? (
        <Routes>
          <Route
            path="/custom-report/:id"
            element={<CustomReportPage />} // Route không dùng layout với Header và Sider
          />
          <Route
            path="*"
            element={
              <>
                <Header className="flex justify-between bg-white header-app px-10 py-[14px] h-full">
                  <HeaderBar listConfig={listConfig} />
                </Header>
                <Layout hasSider={true} className="min-h-screen">
                  <Sider className="!bg-white menu-bar !w-[260px]">
                    <MenuBar />
                  </Sider>
                  <Content className="!bg-[#fbfbfb]">
                    <Routes>
                      {MENU_ROUTES.map((item) => {
                        return (
                          <Route path={item.path} element={item.component} />
                        );
                      })}
                      <Route path="*" element={<Page404 />} />
                    </Routes>
                  </Content>
                </Layout>
              </>
            }
          />
        </Routes>
      ) : (
        <Routes>
          <Route
            path="/auth/login"
            element={<LoginScreen listConfig={listConfig} />}
          />
          <Route
            path="/auth/forgot-password"
            element={<ForgotPasswordScreen listConfig={listConfig} />}
          />
          <Route
            path="/auth/reset-password"
            element={<ChangePasswordScreen listConfig={listConfig} />}
          />
          <Route path="*" element={<Navigate to="/auth/login" />} />
        </Routes>
      )}
    </Layout>
  );
}

export default App;
