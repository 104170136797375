import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../../api/apiConfig';

export const apiPermissionSlice = createApi({
  reducerPath: 'permissionApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ['Permission'],
   endpoints: (builder) => ({
    getListPermission: builder.query({
      query: () => `/${process.env.REACT_APP_API_VERSION}/roles`,
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Permission', id })), { type: 'Permission', id: 'LIST' }]
          : [{ type: 'Permission', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetListPermissionQuery
} = apiPermissionSlice;