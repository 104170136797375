import React from "react";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";

import SelectComponent from "../../components/FormComponent/Select";
import ButtonBase from "../../components/Button";
import { LIST_CHART } from "../../components/Chart";

const HeaderEditor = (props) => {
  const { t } = useTranslation();

  const {
    handleSaveContent,
    typeChart,
    setTypeChart,
    setIsLoadingPreview,
    isLoadingPreview,
  } = props;

  const items = [
    {
      label: t("report.saveFile"),
      key: "1",
      onClick: () => handleSaveContent("save"),
    },
    {
      label: <span>{t("report.download")}</span>,
      key: "2",
      children: [
        {
          label: "Word (.docx)",
          key: "2-1",
          onClick: () => handleSaveContent("download", "export-html-to-word"),
        },
        {
          label: "PDF (.pdf)",
          key: "2-2",
          onClick: () => handleSaveContent("download", "export-html-to-pdf"),
        },
      ],
    },
  ];
  return (
    <div className="flex justify-around my-4 text-xl font-bold">
      <div>{t("history.ScoringByItem")}</div>
      <div className="flex h-[42px]">
        <SelectComponent
          className="w-[150px]"
          options={LIST_CHART}
          value={typeChart}
          onChange={(e) => {
            setTypeChart(e);
          }}
        />
        <ButtonBase
          title={t("report.preview")}
          onClick={() => {
            setIsLoadingPreview(true);
            handleSaveContent("preview");
          }}
          loading={isLoadingPreview}
          className="h-full mx-2"
        />
        <Dropdown
          menu={{ items }}
          placement="bottomRight"
          arrow
          className="h-full"
        >
          <ButtonBase
            title={t("report.file")}
            className="!bg-white !text-[#0B0A0A]"
          />
        </Dropdown>
      </div>
    </div>
  );
};

export default HeaderEditor;
