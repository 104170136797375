import { useTranslation } from "react-i18next";
import { REGEX_EMAIL, REGEX_PASSWORD } from "./regex";

export const useValidationRules = () => {
  const { t } = useTranslation();

  const MailRules = [
    { required: true, message: t("auth.emailRequired") },
    {
      pattern: REGEX_EMAIL,
      message: t("auth.invalidEmail"),
    },
  ];

  const PasswordRules = [
    {
      required: true,
      message: t("auth.passwordRequired"),
    },
    {
      pattern: REGEX_PASSWORD,
      message: t("auth.invalidPassword"),
    },
  ];

  const ConfirmPasswordRules = [
    ({ getFieldValue }) => ({
      validator(_, value) {
        if (value && getFieldValue("password") !== value) {
          return Promise.reject(new Error(t("auth.passwordMismatch")));
        }
        if (!value) {
          return Promise.reject(new Error(t("auth.passwordRequired")));
        }
        return Promise.resolve();
      },
    }),
  ];
  const InputTextRule = {
    max: 255,
    message: t("common.validateInput255"),
  };

  return { MailRules, PasswordRules, ConfirmPasswordRules, InputTextRule };
};
