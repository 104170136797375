import { Form, Modal, Progress, Spin, message } from "antd";
import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import "./index.scss";
import { useTranslation } from "react-i18next";
import {
  useCreateEmployeeMutation,
  useGetEmployeeByIdQuery,
  useUpdateEmployeeMutation,
  useGetUploadUrlMutation,
} from "../../../../store/Employee";
import FormCreateEmployee from "./FormCreatEmployee";
import { DEFAULT_BIRTH } from "../../../../constants";
import withPermissionCheck from "../../../../constants/PermissionHook";
import { useLazyGetJobReportQuery } from "../../../../store/Implement";

const CreateEditEmployee = (props) => {
  const { t } = useTranslation();
  const {
    modalCreate,
    setModalCreate,
    handleSubmit,
    idCompany,
    refetchList,
    hasEditPermission,
  } = props;
  const [form] = Form.useForm();

  const { data: detailEmployee, isLoading: isLoadingDetail } =
    useGetEmployeeByIdQuery(modalCreate?.data?.id || "", {
      skip: !modalCreate?.data?.id,
      refetchOnMountOrArgChange: true,
    });

  const [createEmployee] = useCreateEmployeeMutation();
  const [updateEmployee] = useUpdateEmployeeMutation();
  const [getUploadUrl] = useGetUploadUrlMutation();
  const [sections, setSections] = useState([{ title: "", file: null }]);
  const [listRemove, setListRemove] = useState([]);
  const [triggerGetJobStatus] = useLazyGetJobReportQuery();

  useEffect(() => {
    if (modalCreate.isOpen && modalCreate?.data?.id) {
      const listInterviews = detailEmployee?.data?.interviews?.map((item) => ({
        ...item,
        key: item.id,
        title: item.name,
      }));
      if (listInterviews?.length > 0) setSections(listInterviews);
      form.setFieldsValue({
        name: detailEmployee?.data?.name,
        date_of_birth: detailEmployee?.data?.date_of_birth
          ? dayjs(detailEmployee?.data?.date_of_birth)
          : "",
        hierarchy_id: detailEmployee?.data?.hierarchy_id,
        position_id: detailEmployee?.data?.position_id,
        department_id: detailEmployee?.data?.department_id,
        job_title_id: detailEmployee?.data?.job_title_id,
        employment_type_id: detailEmployee?.data?.employment_type_id,
        ...listInterviews?.reduce(
          (acc, record, _) => ({
            ...acc,
            [`title-${record.key}`]: record.title,
          }),
          {}
        ),
      });
    } else {
      form.setFieldsValue({
        date_of_birth: dayjs(DEFAULT_BIRTH),
      });
    }
  }, [modalCreate, detailEmployee, form]);

  const handleAddSection = () => {
    setSections([
      ...sections,
      { key: dayjs().valueOf(), title: "", file: null, isNew: true },
    ]);
  };
  const [progress, setProgress] = useState(0);
  const [isProgress, setIsProgress] = useState(false);

  const handleSectionChange = async (index, field, value) => {
    if (field === "file") {
      if (value) {
        setIsProgress(true);
        try {
          let payload = {
            file_name: value.name,
          };
          const result = await getUploadUrl(payload).unwrap();

          if (!result) {
            message.error(t("employee.uploadFailed"));
            setIsProgress(false);
            return false;
          }
          const xhr = new XMLHttpRequest();

          // Lắng nghe sự kiện tiến độ để cập nhật progress bar
          xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
              const percentCompleted = Math.round(
                (event.loaded / event.total) * 100
              );
              setProgress(percentCompleted);
            }
          };

          // Xử lý khi upload thành công
          xhr.onload = () => {
            if (xhr.status === 200) {
              message.success(t("employee.uploadSuccess"));
              const newSections = [...sections];
              newSections[index][field] = value;
              newSections[index]["path"] = result?.data?.path;
              newSections[index]["file_name"] = result?.data?.file_name;
              setSections(newSections);
            } else {
              message.error(t("employee.uploadFailed"));
            }
            setIsProgress(false);
            setProgress(0);
          };

          // Xử lý khi upload lỗi
          xhr.onerror = () => {
            message.error(t("employee.uploadFailed"));
            setIsProgress(false);
            setProgress(0);
          };

          // Mở kết nối PUT tới URL đã cung cấp
          xhr.open("PUT", result?.data?.url);

          // Đặt header để gửi dữ liệu dạng binary
          xhr.setRequestHeader("Content-Type", "application/octet-stream");

          // Đọc file dưới dạng binary và gửi đi
          const reader = new FileReader();
          reader.onload = () => {
            const binaryData = reader.result;
            xhr.send(binaryData); // Gửi binary data qua XMLHttpRequest
          };
          reader.readAsArrayBuffer(value);

          // Return false để ngăn chặn upload tự động của Antd
          return false;
        } catch (error) {
          message.error(t("employee.uploadFailed"));
        }
      } else {
        const newSections = [...sections];
        newSections[index][field] = null;
        newSections[index]["path"] = null;
        newSections[index]["file_name"] = null;
        setSections(newSections);
      }
    } else {
      const newSections = [...sections];
      newSections[index][field] = value;
      setSections(newSections);
    }
  };

  const handleRemoveInterview = (indexToRemove, value) => {
    if (value.id) {
      setListRemove([...listRemove, value.id]);
    }
    let array = [...sections];
    const updatedIntervies = array.filter(
      (_, index) => index !== indexToRemove
    );

    setSections(updatedIntervies);
  };

  const onSubmitEmployee = async (values) => {
    const formData = new FormData();
    const fields = [
      { key: "company_id", value: idCompany },
      { key: "name", value: values?.name },
      {
        key: "date_of_birth",
        value: values?.date_of_birth
          ? dayjs(values?.date_of_birth).format("YYYY/MM/DD")
          : null,
      },
      { key: "hierarchy_id", value: values?.hierarchy_id || "" },
      { key: "position_id", value: values?.position_id || "" },
      { key: "department_id", value: values?.department_id },
      { key: "job_title_id", value: values?.job_title_id },
      { key: "employment_type_id", value: values?.employment_type_id },
    ];
    fields.forEach(({ key, value }) => {
      if (value !== undefined && value !== null && value !== "") {
        formData.append(key, value);
      }
    });
    sections.forEach((item, index) => {
      if (item.file_name) {
        formData.append(`interview[${index}][name]`, item.title);
        formData.append(`interview[${index}][file_name]`, item.file_name);
        formData.append(`interview[${index}][path]`, item.path);
      }
      if (item.segments) {
        formData.append(`interviews[${index}][segments]`, item.segments);
      }
      if (item?.id) {
        formData.append(`interviews[${index}][id]`, item.id);
        formData.append(`interviews[${index}][name]`, item.title);
      }
    });
    try {
      if (modalCreate.data) {
        const res = await updateEmployee({
          id: modalCreate.data.id,
          data: formData,
        }).unwrap();
        if (res?.data && res?.data?.job) {
          getPollJobStatus(res?.data?.job?.id, t("setting.updateSuccess"));
        } else {
          message.success(t("setting.updateSuccess"));
          refetchList();
          setModalCreate({ data: {}, isOpen: false });
        }
      } else {
        const res = await createEmployee(formData).unwrap();
        if (res?.data && res?.data?.job) {
          getPollJobStatus(res?.data?.job?.id, t("setting.createSuccess"));
        } else {
          message.success(t("setting.createSuccess"));
          refetchList();
          setModalCreate({ data: {}, isOpen: false });
        }
      }
    } catch (error) {
      if (error.data?.message) {
        message.error(error.data?.message);
      } else {
        message.error(t("setting.errorNetwork"));
      }
    }
  };

  const getPollJobStatus = (jobId, messageText) => {
    let pollingInterval;
    const fetchJobStatus = async () => {
      setIsProgress(true);
      const { data: statusData } = await triggerGetJobStatus(jobId);
      if (statusData) {
        if (statusData?.data?.pendingJobs !== 0) {
          setProgress((prevProgress) => {
            if (prevProgress < 90) {
              return Math.min(prevProgress + 10, 90);
            }
            return 99;
          });

          // Nếu pendingJobs khác 0, tiếp tục gọi API sau 3 giây
          pollingInterval = setTimeout(fetchJobStatus, 3000);
        } else {
          setIsProgress(false);
          message.success(messageText);
          refetchList();
          setModalCreate({ data: {}, isOpen: false });
        }
      }
    };

    fetchJobStatus();

    return () => clearTimeout(pollingInterval);
  };

  return (
    <Modal
      title={t("employee.addStaffModal")}
      open={modalCreate.isOpen}
      footer={false}
      onCancel={() => setModalCreate({ data: {}, isOpen: false })}
      onOk={handleSubmit}
      width={712}
    >
      {isLoadingDetail ? (
        <div className="p-4 flex justify-center">
          <Spin></Spin>
        </div>
      ) : (
        <FormCreateEmployee
          onSubmit={onSubmitEmployee}
          handleRemoveInterview={handleRemoveInterview}
          handleSectionChange={handleSectionChange}
          handleAddSection={handleAddSection}
          listInterviews={sections}
          modalCreate={modalCreate}
          form={form}
          hasEditPermission={hasEditPermission}
          isProgress={isProgress}
        />
      )}
      <Modal
        footer={false}
        open={isProgress}
        onCancel={() => setIsProgress(false)}
        closeIcon={false}
      >
        <div className="mt-4 text-center font-medium">
          {t("employee.pleaseWait")}
          <Spin className="ml-2"></Spin>
        </div>
        <Progress percent={progress} className="mt-4" />
      </Modal>
    </Modal>
  );
};

export default withPermissionCheck(CreateEditEmployee, {
  create: "create-person",
  edit: "detail-person",
});
