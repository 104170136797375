import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../api/apiConfig";

export const apiReportSlice = createApi({
  reducerPath: "ReportApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["Report"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
     getListReports: builder.query({
      query: ( params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/report`,
        params: params,
      }),
      providesTags: (result) =>
        result
          ? [...result.data.data.map(({ id }) => ({ type: 'Report', id })), { type: 'Report', id: 'LIST' }]
          : [{ type: 'Report', id: 'LIST' }],
    }),

    getJobReport: builder.query({
      query: (idJob) => `/${process.env.REACT_APP_API_VERSION}/tracking_job/${idJob}`,
      providesTags: (result, error, id) => [{ type: 'Report', id }],
    }),

    createReport: builder.mutation({
      query: (newReport) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/report`,
        method: "POST",
        body: newReport,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "Report", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),
    getInfoReport: builder.query({
      query: (idReport) => `/${process.env.REACT_APP_API_VERSION}/report/${idReport}`,
      providesTags: (result, error, id) => [{ type: 'Report', id }],
    }),
    getReportInfo: builder.query({
      query: (idReport) => `/${process.env.REACT_APP_API_VERSION}/report/${idReport}`,
      providesTags: (result, error, id) => [{ type: 'Report', id }],
    }),

    updateHtmlReport: builder.mutation({
      query: ({ id, ...updatedEmployee }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/report/${id}/save-html-content`,
        method: 'POST',
        body: updatedEmployee.data,
      }),
      invalidatesTags:  (result, error) => {
        return result ? [{ type: 'Report', id: 'LIST' }] : [] // Invalidate cache to refresh data after create
      }
    }),

    exportReport: builder.query({
      query: ({idReport, key}) => ({
         url: `/${process.env.REACT_APP_API_VERSION}/report/${idReport}/${key}`,
         responseHandler: async(response) => { 
          const blob = await response.blob(); 
          const contentDisposition = response.headers.get('Content-Disposition'); 
          
          let fileName = 'report.pdf';  // Default filename
          if (contentDisposition && contentDisposition.includes('attachment')) {
            const matches = /filename[^;=\n]*=(['"]?)([^;\n]+)\1/.exec(contentDisposition);
            if (matches && matches[2]) {
              fileName = matches[2];
            }
          }
           return { blob, fileName };
        },
      }),    
    }),

    deleteHistoryReport: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/report/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error) => result ?  [{ type: 'Report', id: 'LIST' }] : [],
    }),
  }),
});

export const {
  useGetListReportsQuery,
  useLazyGetJobReportQuery,
  useCreateReportMutation,
  useLazyGetInfoReportQuery,
  useGetReportInfoQuery,
  useDeleteHistoryReportMutation,
  useUpdateHtmlReportMutation,
  useLazyExportReportQuery
} = apiReportSlice;
