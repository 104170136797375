import React from "react";
import { Form, message } from "antd";
import InputComponent from "../../../components/FormComponent/Input";
import ButtonBase from "../../../components/Button";
import { useTranslation } from "react-i18next";
import { useValidationRules } from "../../../constants/rules";
import { useForgotPasswordMutation } from "../../../store/Auth/ForgotPassword";
import { renderConfigApp } from "../../../constants/ultil";

const ForgotPasswordContainer = ({ listConfig }) => {
  const { t } = useTranslation();
  const { MailRules } = useValidationRules();
  const [form] = Form.useForm();
  const [forgotPassword, { isLoading }] = useForgotPasswordMutation();

  const onSubmitSendMail = async (value) => {
    try {
      await forgotPassword(value);
      message.success(t("auth.alerSendMailSuccess"));
      form.resetFields();
    } catch (error) {
      if (error?.data?.email) {
        message.error(error?.data?.email?.[0]);
      } else {
        message.error(error?.data?.message);
      }
    }
  };
  return (
    <div className="bg-[#999999] min-h-screen">
      <div className="pl-10 py-[14px]">
        <img
          src={renderConfigApp(listConfig?.data, "vi", "site_logo")}
          width={215}
          alt="logo"
        />
      </div>
      <div className="flex justify-around">
        <div className="text-white text-[56px] font-bold flex items-center">
          {renderConfigApp(listConfig?.data, "vi", "site_title")}
        </div>
        <div className="bg-white rounded w-[375px] h-full mt-10 p-6">
          <div className="text-xl font-bold text-[var(--primary-color)] mb-[16px]">
            {t("auth.restPassword")}
          </div>
          <div className="header-modal-login"></div>
          <Form className="mt-6" form={form} onFinish={onSubmitSendMail}>
            <Form.Item name="email" rules={MailRules}>
              <InputComponent
                title={t("auth.email")}
                placeholder={t("auth.pleaseInputEmail")}
                required
              />
            </Form.Item>
            <div className="text-[11px] text-[#655f5f] mt-[-20px]">
              {t("auth.noteForInputMail")}
            </div>

            <ButtonBase
              title={t("auth.sendMail")}
              htmlType="submit"
              className="w-[327px] h-11 mt-4"
              loading={isLoading}
            />
          </Form>
          <div className="header-modal-login mt-6"></div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
