import React, { useState } from "react";
import { Form, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import SelectComponent from "../../../components/FormComponent/Select";
import ButtonBase from "../../../components/Button";
import { useGetListCompanyQuery } from "../../../store/Company";
import { useGetListConceptsQuery } from "../../../store/Concepts";
import { useGetListEmployeesQuery } from "../../../store/Employee";
import DatePickerComponent from "../../../components/FormComponent/DatePicker";
import { useGetListIndustryQuery } from "../../../store/Settings/Industry";
import { LIST_ANALYSIS_TARGET } from "../../../constants";

const FilterHistoryContainer = (props) => {
  const { t } = useTranslation();
  const { listJobTitle, listPositions, setParamsSearch } = props;
  const [companyId, setCompanyId] = useState("");
  const [form] = Form.useForm();
  const [params] = useState({
    limit: 1000,
    page: 1,
  });
  const { data: listCompany } = useGetListCompanyQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listConcepts } = useGetListConceptsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listEmployees } = useGetListEmployeesQuery(
    {
      id: companyId,
      params: { ...params },
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );

  const { data: listIndustry } = useGetListIndustryQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });

  const onSubmit = (values) => {
    setParamsSearch((prevParams) => {
      return {
        ...prevParams,
        ...values,
        page: 1,
        limit: 10,
      };
    });
  };

  return (
    <div className="bg-white px-6 py-4 h-full header-app">
      <Form onFinish={onSubmit} form={form}>
        <Row gutter={[16, 0]}>
          <Col span={4} className="mt-2 text-[13px]">
            {t("history.searchCompany")}
          </Col>
          <Col span={7}>
            <Form.Item name="company_id" className="mb-4">
              <SelectComponent
                allowClear
                placeholder={t("history.enterCompanyName")}
                showSearch
                optionFilterProp="label"
                options={listCompany?.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(e) => {
                  setCompanyId(e);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={2}></Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("history.concept")}
          </Col>
          <Col span={7}>
            <Form.Item name="concept_id" className="mb-4">
              <SelectComponent
                allowClear
                placeholder={t("history.enterConcept")}
                options={listConcepts?.data?.data?.map((item) => ({
                  label: item.name,
                  value: item.id,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={4} className="mt-2 text-[13px]">
            {t("history.searchDate")}
          </Col>
          <Col span={7}>
            <Form.Item name="created_at" className="mb-4">
              <DatePickerComponent
                allowClear
                placeholder={t("history.selectDate")}
              />
            </Form.Item>
          </Col>
          <Col span={2}></Col>

          <Col span={4} className="mt-2 text-[13px]">
            {t("history.staffName")}
          </Col>
          <Col span={7}>
            <Form.Item name="person_id" className="mb-4">
              <SelectComponent
                allowClear
                placeholder={t("history.enterStaffName")}
                options={listEmployees?.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("employee.position")}
          </Col>
          <Col span={7}>
            <Form.Item name="position_id" className="mb-4">
              <SelectComponent
                allowClear
                options={listPositions?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder={t("history.enterPosition")}
              />
            </Form.Item>
          </Col>

          <Col span={2}></Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("common.industry")}
          </Col>
          <Col span={7}>
            <Form.Item name="industry_id" className="mb-4">
              <SelectComponent
                allowClear
                options={listIndustry?.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder={t("history.enterIndusry")}
              />
            </Form.Item>
          </Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("concept.analysisTarget")}
          </Col>
          <Col span={7}>
            <Form.Item name="target" className="mb-4">
              <SelectComponent allowClear options={LIST_ANALYSIS_TARGET} />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("common.jobTitle")}
          </Col>
          <Col span={7}>
            <Form.Item name="job_title_id" className="mb-4">
              <SelectComponent
                allowClear
                options={listJobTitle?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                placeholder={t("history.enterJobTitle")}
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="flex justify-end">
          <ButtonBase
            className="w-[82px] h-[36px] mr-2"
            title={t("history.refine")}
            htmlType="submit"
          />
          <ButtonBase
            className="w-[82px] h-[36px] !bg-white !text-[#0B0A0A]"
            title={t("history.clear")}
            htmlType="button"
            onClick={() => {
              form.resetFields();
              setParamsSearch({ page: 1, limit: 10 });
            }}
          />
        </div>
      </Form>
    </div>
  );
};

export default FilterHistoryContainer;
