import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiJobTitleSlice = createApi({
  reducerPath: "JobTitleApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["JobTitle"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListJobTitle: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/job_title`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: "JobTitle",
                id,
              })),
              { type: "JobTitle", id: "LIST" },
            ]
          : [{ type: "JobTitle", id: "LIST" }],
    }),

    createJobTitle: builder.mutation({
      query: (newJobTitle) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/job_title`,
        method: "POST",
        body: newJobTitle,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "JobTitle", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateJobTitle: builder.mutation({
      query: ({ id, ...updatedJobTitle }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/job_title/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedJobTitle.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "JobTitle", id }] : [],
    }),

    deleteJobTitle: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/job_title/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "JobTitle", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/job_title/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "JobTitle", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListJobTitleQuery,
  useCreateJobTitleMutation,
  useUpdateJobTitleMutation,
  useDeleteJobTitleMutation,
  useSwapMutation,
} = apiJobTitleSlice;
