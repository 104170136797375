import React from "react";
import { GrapesjsReact } from "grapesjs-react";
import grapesjsNewsletter from "grapesjs-preset-newsletter";
import grapesjsForms from "grapesjs-plugin-forms";
import grapesjsExport from "grapesjs-plugin-export";
import "grapesjs/dist/css/grapes.min.css";
import { useTranslation } from "react-i18next";

const listBlockRemove = [
  "sect100",
  "sect50",
  "image",
  "sect30",
  "sect37",
  "grid-items",
  "list-items",
  "select",
  "checkbox",
  "radio",
  "form",
  "quote",
  "link",
  "link-block",
  "button",
  "input",
  "textarea",
];
const listPanelRemove = [
  "undo",
  "redo",
  "sw-visibility",
  "export-template",
  "gjs-open-import-template",
  "gjs-toggle-images",
  "preview",
  "fullscreen",
  "canvas-clear",
];
const listDeviceRemove = [
  "set-device-mobile",
  "set-device-tablet",
  "set-device-desktop",
];
const listCustomEditorRemove = [
  "open-sm",
  "open-tm",
  "open-layers",
  "open-blocks",
];
const additionalStyles = `
    .report-title {
        margin-top: 20px;
        font-weight: 700;
    }
    .custom-table {
        width: 100%;
        border-collapse: collapse;
        text-align: left;
        border: 1px solid #ddd;
        margin: 20px 0 50px 0;
    }
    .custom-table th, .custom-table td {
        padding: 10px;
        border: 1px solid #ddd;
    }
    .custom-table th {
        text-align: center;
    }
    .data-cell {
        text-align: center;
    }
`;

const ContentEditor = (props) => {
  const {
    forceUpdate,
    jsonHtml,
    jsonCSS,
    jsonData,
    dataTable,
    dataUrl,
    scoreScale,
  } = props;
  const { t } = useTranslation();

  const generateHtmlFromData = () => {
    if (jsonHtml) {
      if (jsonCSS) {
        window.editor?.setStyle(jsonCSS);
      }
      return jsonHtml;
    }
    return jsonData
      ?.map(
        (item) =>
          `<div style="padding: 10px 0">
          <h2>${item.name || "N/A"}</h2>
          <p>${item.score || "N/A"}  点</p>
          <p>理由</p>
          <p>${item.description || "No description available"}</p>
        </div>`
      )
      .join("");
  };

  const createCustomTable = (charts) => {
    const { datasets, labels } = charts;

    let tableHtml = `
    <div class="report-title">${t("report.allCompanyReport")}</div>
    <table class="custom-table">
      <thead>
        <tr>
          <th class="empty-cell"></th>
          <th class="data-label">${datasets?.label}</th>
        </tr>
      </thead>
      <tbody>`;

    labels?.forEach((label, index) => {
      tableHtml += `
      <tr>
        <td class="label-cell">${label}</td>
        <td class="data-cell">${datasets.data[index]} 点</td>
      </tr>`;
    });

    tableHtml += `</tbody></table>`;

    return tableHtml;
  };
  return (
    <GrapesjsReact
      key={forceUpdate}
      id="grapesjs-react"
      plugins={[
        grapesjsNewsletter,
        grapesjsForms,
        grapesjsExport,
        (editor) => {
          const panelManager = editor.Panels;
          listDeviceRemove.forEach((item) => {
            panelManager.removeButton("devices-c", item);
          });
          listPanelRemove.forEach((item) => {
            panelManager.removeButton("options", item);
          });
          listCustomEditorRemove.forEach((item) => {
            panelManager.removeButton("views", item);
          });
          // Thêm nút "Save" vào thanh công cụ
          // panelManager.addButton("options", {
          //   id: "save-button",
          //   className: "fa fa-save",
          //   command: "save-project", // Command liên kết
          //   attributes: { title: t("common.submit") },
          // });
          // Thêm command "save-project" để xử lý hành động lưu
          // editor.Commands.add("save-project", {
          //   run: () => {
          //     saveContent();
          //   },
          // });
          // panelManager.addButton("options", {
          //   id: "download-button",
          //   className: "fa fa-download",
          //   command: "download-report", // Command liên kết
          //   attributes: { title: "ダウンロード" },
          // });
          // Thêm command "download-report" để xử lý hành động tải về
          // editor.Commands.add("download-report", {
          //   run: () => {
          //     downloadContent();
          //   },
          // });
          const blockManager = editor.BlockManager;
          listBlockRemove.forEach((item) => {
            blockManager.remove(item);
          });
        },
      ]}
      onInit={(editor) => {
        window.editor = editor;
        const htmlContent = generateHtmlFromData();
        const tableHtml = createCustomTable(dataTable);
        const imgTagRegex = /<img\b[^>]*>/i;
        if (!imgTagRegex.test(htmlContent) && scoreScale) {
          editor.addComponents(tableHtml);
          editor.addComponents(
            `<img src="${dataUrl}" id="chart-image" style="width: 100%; height: 50%" />`
          );
        }
        editor.addStyle(additionalStyles);
        editor.addComponents(htmlContent);

        // Set css for the wrapper
        const wrapper = editor.getWrapper();
        wrapper.addStyle({
          "max-width": "800px",
          margin: "0 auto",
          "font-family": "'Noto Sans JP', sans-serif",
        });
      }}
    />
  );
};

export default ContentEditor;
