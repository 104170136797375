import { createApi } from "@reduxjs/toolkit/query/react";
import baseQueryWithErrorHandling from "../../../api/apiConfig";

export const apiScoreScaleSlice = createApi({
  reducerPath: "scoreScaleApi", // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  tagTypes: ["ScoreScale"], // Tag để invalidate cache cho các thao tác như create, update, delete
  endpoints: (builder) => ({
    getListScoreScale: builder.query({
      query: (params) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/master-data/score-scale`,
        params: params, // Gửi tham số lên API
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.data.data.map(({ id }) => ({
                type: "ScoreScale",
                id,
              })),
              { type: "ScoreScale", id: "LIST" },
            ]
          : [{ type: "ScoreScale", id: "LIST" }],
    }),

    createScoreScale: builder.mutation({
      query: (newScoreScale) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/master-data/score-scale`,
        method: "POST",
        body: newScoreScale,
      }),
      invalidatesTags: (result, error) => {
        return result ? [{ type: "ScoreScale", id: "LIST" }] : []; // Invalidate cache to refresh data after create
      },
    }),

    updateScoreScale: builder.mutation({
      query: ({ id, ...updatedScoreScale }) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/master-data/score-scale/${id}`,
        method: "PUT", // Có thể dùng PATCH nếu muốn cập nhật một phần dữ liệu
        body: updatedScoreScale.data,
      }),
      invalidatesTags: (result, error, { id }) =>
        result ? [{ type: "ScoreScale", id }] : [],
    }),

    deleteScoreScale: builder.mutation({
      query: (id) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/master-data/score-scale/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) =>
        result ? [{ type: "ScoreScale", id: "LIST" }] : [],
    }),

    swap: builder.mutation({
      query: (orderSwap) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/master-data/score-scale/swap`,
        method: "POST",
        body: orderSwap, // Gửi oldIndex và newIndex
      }),
      invalidatesTags: [{ type: "ScoreScale", id: "LIST" }],
    }),
  }),
});

export const {
  useGetListScoreScaleQuery,
  useCreateScoreScaleMutation,
  useUpdateScoreScaleMutation,
  useDeleteScoreScaleMutation,
  useSwapMutation,
} = apiScoreScaleSlice;
