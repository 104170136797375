import React, { useState } from "react";
import { Form, Col, Row } from "antd";
import { useTranslation } from "react-i18next";
import SelectComponent from "../../../components/FormComponent/Select";
import ButtonBase from "../../../components/Button";
import {
  ORGANIZATION,
  HIERARCHY,
  INDIVIDUAL,
  LIST_ANALYSIS_TARGET,
} from "../../../constants";
import { useGetListCompanyQuery } from "../../../store/Company";
import { useGetListConceptsQuery } from "../../../store/Concepts";
import { useGetListEmployeesQuery } from "../../../store/Employee";
import { useGetListCompanyHierarchyQuery } from "../../../store/Settings/CompanyHierarchy";

const FormCreateReport = (props) => {
  const { t } = useTranslation();
  const { onSubmit, hasCreatePermission, isLoadingCreateReport } = props;
  const [target, setTarget] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [conceptId, setConceptId] = useState("");
  const [personId, setPersonId] = useState("");
  const [form] = Form.useForm();
  const [params] = useState({
    limit: 1000,
    page: 1,
  });
  const [selectedValues, setSelectedValues] = React.useState([]);
  const { data: listCompany } = useGetListCompanyQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listConcepts } = useGetListConceptsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const { data: listEmployees } = useGetListEmployeesQuery(
    {
      id: companyId,
      params: { ...params },
    },
    {
      skip: !companyId,
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );
  const { data: listCompanyHierarchy } = useGetListCompanyHierarchyQuery(
    params,
    {
      refetchOnMountOrArgChange: true, // auto call API when component mount
    }
  );
  const options = [
    {
      value: "",
      label: t("report.selectAllEmployee"),
      disabled: selectedValues.length > 0 && !selectedValues.includes(""),
    },
    ...(listEmployees?.data?.data || []).map((item) => ({
      value: item.id,
      label: item.name,
      disabled: selectedValues.includes(""),
    })),
  ];

  return (
    <div className="bg-white px-6 py-4 h-full header-app">
      <Form form={form} onFinish={onSubmit}>
        <Row gutter={[16, 0]}>
          <Col span={4} className="mt-2 text-[13px]">
            {t("concept.analysisTarget")}
            <span className="text-[#ff0000]">*</span>
          </Col>
          <Col span={7}>
            <Form.Item
              name="target"
              rules={[
                { required: true, message: t("setting.pleaseEnterField") },
              ]}
              className="mb-4"
            >
              <SelectComponent
                options={LIST_ANALYSIS_TARGET}
                onChange={(e) => {
                  setSelectedValues([]);
                  form.setFieldsValue({ concept_id: "", company_persons: [] });
                  setTarget(e);
                }}
              />
            </Form.Item>
          </Col>

          <Col span={2}></Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("report.selectCompany")}
            <span className="text-[#ff0000]">*</span>
          </Col>
          <Col span={7}>
            <Form.Item
              name="company_id"
              rules={[
                { required: true, message: t("setting.pleaseEnterField") },
              ]}
              className="mb-4"
            >
              <SelectComponent
                showSearch
                optionFilterProp="label"
                options={listCompany?.data?.data?.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                onChange={(e) => {
                  setCompanyId(e);
                  setSelectedValues([]);
                  form.setFieldsValue({ person_id: "", company_persons: [] });
                }}
              />
            </Form.Item>
          </Col>
          {target === ORGANIZATION && (
            <>
              <Col span={4} className="mt-2 text-[13px]">
                {t("report.employeee")}
                <span className="text-[#ff0000]">*</span>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="company_persons"
                  rules={[
                    { required: true, message: t("setting.pleaseEnterField") },
                  ]}
                  className="mb-4"
                >
                  <SelectComponent
                    mode="multiple"
                    options={options}
                    value={selectedValues}
                    onChange={(values) => {
                      if (values.includes("")) {
                        // Khi selected "全員選択", chỉ giữ giá trị "" và disable các mục khác
                        setSelectedValues([""]);
                        form.setFieldValue("company_persons", [""]);
                      } else {
                        // enabled "全員選択" nếu không có mục nào được chọn
                        setSelectedValues(values);
                        form.setFieldValue("company_persons", values);
                      }
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={13}></Col>
            </>
          )}
          <Col span={4} className="mt-2 text-[13px]">
            {t("report.selectConcept")}
            <span className="text-[#ff0000]">*</span>
          </Col>
          <Col span={7}>
            <Form.Item
              name="concept_id"
              rules={[
                { required: true, message: t("setting.pleaseEnterField") },
              ]}
              className="mb-4"
            >
              <SelectComponent
                options={listConcepts?.data?.data
                  ?.filter((item) => item.type === target)
                  ?.map((item) => ({
                    label: item.name,
                    value: item.id,
                  }))}
                onChange={(e) => {
                  setConceptId(e);
                  form.setFieldsValue({ prompt_ids: [] });
                }}
              />
            </Form.Item>
          </Col>
          <Col span={2}></Col>
          <Col span={4} className="mt-2 text-[13px]">
            {t("report.selectPrompt")}
            <span className="text-[#ff0000]">*</span>
          </Col>
          <Col span={7}>
            <Form.Item
              name="prompt_ids"
              className="mb-4"
              rules={[
                { required: true, message: t("setting.pleaseEnterField") },
              ]}
            >
              <SelectComponent
                mode="multiple"
                options={listConcepts?.data?.data
                  ?.find((item) => item.id === conceptId)
                  ?.prompts.map((prompt) => ({
                    label: prompt.title,
                    value: prompt.id,
                  }))}
                maxTagCount="responsive"
              />
            </Form.Item>
          </Col>
          {target === HIERARCHY && (
            <>
              <Col span={4} className="mt-2 text-[13px]">
                {t("common.hierarchy")}
                <span className="text-[#ff0000]">*</span>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="hierarchy_id"
                  rules={[
                    { required: true, message: t("setting.pleaseEnterField") },
                  ]}
                  className="mb-4"
                >
                  <SelectComponent
                    options={listCompanyHierarchy?.data?.data?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                  />
                </Form.Item>
              </Col>
            </>
          )}
          {target === INDIVIDUAL && (
            <>
              <Col span={4} className="mt-2 text-[13px]">
                {t("report.selectStaff")}
                <span className="text-[#ff0000]">*</span>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="person_id"
                  rules={[
                    { required: true, message: t("setting.pleaseEnterField") },
                  ]}
                  className="mb-4"
                >
                  <SelectComponent
                    options={listEmployees?.data?.data?.map((item) => ({
                      value: item.id,
                      label: item.name,
                    }))}
                    onChange={(e) => {
                      setPersonId(e);
                      form.setFieldsValue({ interview_id: "" });
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={2}></Col>
              <Col span={4} className="mt-2 text-[13px]">
                {t("report.selectVideo")}
                <span className="text-[#ff0000]">*</span>
              </Col>
              <Col span={7}>
                <Form.Item
                  name="interview_id"
                  rules={[
                    { required: true, message: t("setting.pleaseEnterField") },
                  ]}
                  className="mb-4"
                >
                  <SelectComponent
                    options={listEmployees?.data?.data
                      ?.find((item) => item.id === personId)
                      ?.interviews.map((iterview) => ({
                        label: iterview.name,
                        value: iterview.id,
                      }))}
                  />
                </Form.Item>
              </Col>
            </>
          )}
        </Row>
        <div className="flex justify-end">
          <ButtonBase
            className="w-[82px] h-[36px]"
            title={t("report.submit")}
            htmlType="submit"
            disabled={!hasCreatePermission}
            loading={isLoadingCreateReport}
          />
        </div>
      </Form>
    </div>
  );
};

export default FormCreateReport;
