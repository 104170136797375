import React, { useState } from "react";

import ButtonBase from "../../components/Button/index";
import TableBaseComponent from "../../components/FormComponent/Table";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import HeaderContent from "../../components/HeaderContent";
import {
  useDeleteAccountMutation,
  useGetListAccountsQuery,
} from "../../store/Account";
import { useNavigate } from "react-router-dom";
import PopConfirmBase from "../../components/PopupConfirm";
import { handlePageChange, handlePageSizeChange } from "../../constants/ultil";
import withPermissionCheck from "../../constants/PermissionHook";

const ListAccountContainer = ({
  hasCreatePermission,
  hasDetailPermission,
  hasDeletePermission,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useState({
    limit: 10,
    page: 1,
  });
  const { data: listAccounts, isFetching } = useGetListAccountsQuery(params, {
    refetchOnMountOrArgChange: true, // auto call API when component mount
  });
  const [deleteAccount] = useDeleteAccountMutation();

  const onDeleteAccount = async (record) => {
    try {
      await deleteAccount(record.id).unwrap();
      message.success(t("setting.deleteSuccess"));
    } catch (error) {
      message.error(error?.data?.message);
    }
  };
  const columns = [
    {
      title: "ID",
      key: "id",
      dataIndex: "id",
      width: 100,
    },
    {
      title: t("account.fullName"),
      key: "name",
      dataIndex: "name",
    },
    {
      title: t("account.role"),
      key: "role",
      dataIndex: "role",
    },
    {
      title: t("auth.email"),
      key: "email",
      dataIndex: "email",
      render: (_, record) => {
        return record.email;
      },
    },
    {
      title: t("setting.operation"),
      key: "action",
      render: (_, record) => (
        <div className="flex cursor-pointer font-medium">
          <div
            className={`mr-5 text-[var(--primary-color)] ${
              hasDetailPermission ? "" : "!text-[#a9a9a9] cursor-not-allowed"
            }`}
            onClick={() => {
              if (hasDetailPermission) {
                navigate(`/account/edit/${record.id}`);
              }
            }}
          >
            {t("setting.edit")}
          </div>
          {hasDeletePermission ? (
            <PopConfirmBase
              title={t("common.confirmDelete")}
              onConfirm={() => onDeleteAccount(record)}
            >
              <div className="font-medium text-[var(--primary-color)] cursor-pointer">
                {t("setting.delete")}
              </div>
            </PopConfirmBase>
          ) : (
            <div className="font-medium text-[#a9a9a9] cursor-not-allowed">
              {t("setting.delete")}
            </div>
          )}
        </div>
      ),
    },
  ];

  return (
    <div>
      <div className="flex justify-between bg-white border-b border-[#e4e4e4]">
        <HeaderContent
          textCurrentPage={t("common.accountList")}
          className="ml-3"
        />
        <div className="flex items-center pr-5">
          <ButtonBase
            className="w-[108px] h-[36px]"
            title={t("account.newRegistration")}
            onClick={() => navigate("/account/create")}
            disabled={!hasCreatePermission}
          />
        </div>
      </div>
      <div className="p-4">
        <TableBaseComponent
          columns={columns}
          dataSource={listAccounts?.data?.data}
          paginationOptions={{
            total: listAccounts?.data?.total,
            defaultPageSize: params.limit,
            current: params?.page,
          }}
          handlePageChange={(pageChange) =>
            handlePageChange(pageChange, setParams)
          }
          handlePageSizeChange={(current, size) =>
            handlePageSizeChange(size, setParams)
          }
          loading={isFetching}
        />
      </div>
    </div>
  );
};
export default withPermissionCheck(ListAccountContainer, {
  view: "list-users",
  create: "create-user",
  detail: "detail-user",
  delete: "delete-user",
});
