import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithErrorHandling from '../../../api/apiConfig';

export const apiForgotPasswordSlice = createApi({
  reducerPath: 'forgotPasswordApi', // name reducer of API slice
  baseQuery: baseQueryWithErrorHandling,
  endpoints: (builder) => ({
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/${process.env.REACT_APP_API_VERSION}/forgot-password`,
        method: 'POST',
        body: data,
      }),
    }),

  }),
});

export const { useForgotPasswordMutation } = apiForgotPasswordSlice;